import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

type Store = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const store = create<Store>()((set) => ({
  isOpen: false,
  onClose: () => {
    set({ isOpen: false });
  },
  onOpen: () => set({ isOpen: true }),
}));

export const useFeedbackModal = createSelectorHooks(store);
