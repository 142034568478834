import { Box, HStack, Text, Textarea } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';

interface Props {
  title: string;
  description?: string;
  additionalBlock?: ReactNode;
  icon: AvailableIcons;
  iconSize?: string;
  inputValue?: string;
  onChange?: (value: string) => void;
  inputPlaceholder?: string;
}

export const FeedbackSection: FC<Props> = ({
  description,
  icon,
  additionalBlock,
  iconSize = '6',
  title,
  inputPlaceholder,
  inputValue,
  onChange,
}) => {
  return (
    <HStack
      gap='4'
      w='full'
      p='6'
      border='1px solid'
      borderColor='border'
      borderRadius='4px'
    >
      <Box
        boxSize='50px'
        p='3'
        bg='form.inputBackground'
        border='1px solid'
        borderColor='form.inputBorder'
        borderRadius='4px'
      >
        <Icon icon={icon} boxSize={iconSize} />
      </Box>

      <Box w='full'>
        <Text fontSize='sm' fontWeight='bold'>
          {title}
        </Text>
        {description && <Text fontSize='sm'>{description}</Text>}
        {additionalBlock != null && (
          <Box color='color' fontSize='sm'>
            {additionalBlock}
          </Box>
        )}
        {onChange != null && inputValue != null && (
          <Textarea
            w='full'
            minH='20'
            mt='6'
            fontSize='sm'
            bg='form.inputBackground'
            borderColor='form.inputBorder'
            _active={{
              borderColor: 'form.activeBorder',
              bg: 'secondaryBackground',
            }}
            _focus={{
              borderColor: 'form.activeBorder',
              bg: 'secondaryBackground',
            }}
            _placeholder={{ color: 'placeholderText' }}
            onChange={(e) => onChange(e.target.value)}
            placeholder={inputPlaceholder}
            value={inputValue}
          />
        )}
      </Box>
    </HStack>
  );
};
