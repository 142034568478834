import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { AuthActionCard } from '$/components/core/Projects/components/Cards/AuthActionCard';
import { UploadPictureCard } from '$/components/core/Projects/components/Cards/UploadPictureCard';
import { useIsMobile } from '$/hooks/useIsMobile';
import { DashboardButtonRow } from '$/pages/DashboardPages/components/DashboardButtonRow';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { ProjectModal } from '$/pages/DashboardPages/pages/Projects/components/ProjectModal';
import { ProjectRowItems } from '$/pages/DashboardPages/pages/Welcome/components/ProjectSection/components/ProjectRowItems';
import { projectsQuery } from '$/services/usecases/projects/queries';

export const ProjectSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const user = useAuthenticationStore.useUser();
  const { data } = useQuery(projectsQuery({ enabled: user != null }));

  const { getProjectCountLimit, hasPermission } = useAuthorization();

  const projectCount = data?.response.payload.length ?? 0;

  const getHeadline = () => {
    if (hasPermission('General_Project_Page')) {
      if (hasPermission('General_Welcome_Project_Count')) {
        return t('dashboard.welcome.lastUsedCount', {
          userProjectCount: projectCount,
          projectCountLimit: getProjectCountLimit(),
        });
      }
      return t('dashboard.welcome.lastUsed');
    }

    if (hasPermission('General_Welcome_Project_Count')) {
      return t('dashboard.welcome.ownProjectCount', {
        userProjectCount: projectCount,
        projectCountLimit: getProjectCountLimit(),
      });
    }
    return t('dashboard.welcome.ownProjectPic');
  };

  const additionalProperties = hasPermission('General_Project_Page')
    ? { showAllUrl: '/projects' as const }
    : {};

  return (
    <SectionHeadline headline={getHeadline()} {...additionalProperties}>
      {hasPermission('Project_Open') ? (
        <DashboardSectionRow>
          <ProjectRowItems />
        </DashboardSectionRow>
      ) : (
        <DashboardButtonRow>
          {!isMobile && <UploadPictureCard />}
          <AuthActionCard />
        </DashboardButtonRow>
      )}
      <ProjectModal />
    </SectionHeadline>
  );
};
