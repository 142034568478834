import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { usePiwikSearchTracking } from '$/hooks/usePiwikSearchTracking';
import { mapFilterTypeToMaterialTypes } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import {
  localFilteredMaterialsQuery,
  materialsQuery,
} from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  searchLabel: string;
  idFilter?: string[];
  isDrawerOpen: boolean;
  openSearchDrawer: () => void;
  closeSearchDrawer: () => void;
  onResultClick?: (material: Material) => void;
}

export const MobileSearchDrawer: FC<Props> = ({ searchLabel, idFilter }) => {
  const activeType = useCollectionStore.useActiveType();
  const query = useMaterialSearchStore.useQuery();

  const activeMaterialTypes = activeType
    ? mapFilterTypeToMaterialTypes(activeType)
    : undefined;

  const usedQuery = idFilter
    ? localFilteredMaterialsQuery({
        query,
        limit: 10,
        ids: idFilter,
        type: activeMaterialTypes,
      })
    : materialsQuery({ query, limit: 10, type: activeMaterialTypes });

  const { data } = useQuery(usedQuery);

  usePiwikSearchTracking(data);

  return <SearchInput searchLabel={searchLabel} />;
};
