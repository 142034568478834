import { Stack, HStack, IconButton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const ShapeSection = () => {
  const { t } = useTranslation();

  const { type } = useEditorStore.useColorChecker();
  const { setColorCheckerSettings } = useEditorActions();

  const shapes = [
    { id: 1, icon: <Icon icon='shape_rectangle' boxSize='3' /> },
    { id: 3, icon: <Icon icon='shape_circle' boxSize='3.5' /> },
    { id: 0, icon: <Icon icon='shape_rectangle_filled' boxSize='2.5' /> },
    { id: 2, icon: <Icon icon='shape_circle_filled' boxSize='3' /> },
  ];

  return (
    <Stack>
      <Text fontSize='sm'>{t('editor.colorChecker.shape')}</Text>
      <HStack>
        {shapes.map((shape) => (
          <IconButton
            key={shape.id}
            w='full'
            h='32px'
            color={
              type === shape.id
                ? 'primaryButton.activeBackground'
                : 'primaryButton.text'
            }
            bg={
              type === shape.id
                ? 'visualization.transparentBlue'
                : 'transparent'
            }
            border='1px solid'
            borderColor={type === shape.id ? 'visualization.blue' : 'border'}
            aria-label={`shape-${shape.id}`}
            icon={shape.icon}
            onClick={() => setColorCheckerSettings({ shape: shape.id })}
            variant='secondary'
          />
        ))}
      </HStack>
    </Stack>
  );
};
