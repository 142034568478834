import {
  Button,
  HStack,
  Input,
  Slider,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface PropertySliderProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
}

const PropertySlider: FC<PropertySliderProps> = ({
  value,
  onChange,
  min = 0,
  max = 100,
}) => {
  const [inputValue, setInputValue] = useState<number>(value);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }

    if (inputValue === value) return;

    timeoutId = window.setTimeout(() => onChange(inputValue), 250);

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
    // prevent unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = parseInt(e.currentTarget.value);
    if (isNaN(parsedNumber)) setInputValue(min);
    else setInputValue(parsedNumber);
  };

  const handleInputBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    const transformer = (val: number) =>
      val < min ? min : val > max ? max : val;

    if (e.currentTarget.value === '') setInputValue(value);
    else onChange(transformer(parseInt(e.currentTarget.value)));
  };

  return (
    <HStack gap='4'>
      <Slider max={max} min={min} onChange={setInputValue} value={inputValue}>
        <SliderTrack
          h='10px'
          bgGradient='linear(to-r, neutral.1000, white)'
          borderWidth='1px'
          borderColor='border'
          borderRadius='full'
        />
        <SliderThumb
          w='16px'
          h='16px'
          bg='background'
          borderWidth='1px'
          borderColor='border'
        />
      </Slider>

      <Input
        w='50px'
        minW='50px'
        h='32px'
        px='2'
        textAlign='center'
        bg='transparent'
        _hover={{}}
        _focusVisible={{}}
        outline='none'
        max={max}
        min={min}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === 'Enter' && e.currentTarget.blur()}
        type='number'
        value={inputValue}
      />
    </HStack>
  );
};

export const ColorProperties = () => {
  const { t } = useTranslation();
  const { setElementSettings } = useEditorActions();

  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const activeComponent = useEditorStore(
    useShallow(({ activeComponentIndex, components }) =>
      activeComponentIndex != null ? components[activeComponentIndex] : null,
    ),
  );

  const onChange = (data: { contrast?: number; brightness?: number }) => {
    if (activeComponentIndex != null && data.contrast != null) {
      setElementSettings(activeComponentIndex, { contrast: data.contrast });
    }

    if (activeComponentIndex != null && data.brightness != null) {
      setElementSettings(activeComponentIndex, {
        brightness: data.brightness,
        autoBrightness: 0,
      });
    }
  };

  const resetToAuto = () => {
    if (activeComponentIndex != null) {
      setElementSettings(activeComponentIndex, {
        autoBrightness: 1,
      });
    }
  };

  return (
    <Stack gap='3'>
      <Stack gap='1' px='4'>
        <Text fontSize='sm' lineHeight='20px'>
          {t('editor.colorChecker.contrast')}
        </Text>
        <PropertySlider
          value={activeComponent?.contrast ?? 0}
          onChange={(contrast) => onChange({ contrast })}
        />
      </Stack>
      <SidebarDivider />
      <Stack gap='3' px='4'>
        <Stack gap='1'>
          <Text fontSize='sm' lineHeight='20px'>
            {t('editor.colorChecker.brightness')}
          </Text>
          <PropertySlider
            value={255 - (activeComponent?.brightness ?? 0)}
            onChange={(brightness) =>
              onChange({ brightness: 255 - brightness })
            }
            max={255}
          />
        </Stack>
        <Button
          h='32px'
          fontSize='xs'
          fontWeight='normal'
          onClick={resetToAuto}
          variant='secondary'
        >
          {t('editor.colorChecker.resetToAuto')}
        </Button>
      </Stack>
    </Stack>
  );
};
