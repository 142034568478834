import { Box, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareSection';
import { ColorRepresentationSelection } from '$/components/core/Editor/ColorSelectionSidebar/ColorRepresentationSelection';
import { CurrentMaterialSection } from '$/components/core/Editor/ColorSelectionSidebar/CurrentMaterialSection';
import { FanPageSelector } from '$/components/core/Editor/ColorSelectionSidebar/FanPageSelector';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderList';
import { FanSelectionFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FanSelectionFilter';
import { MoreSelectionFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/MoreSelectionFilter';
import { FilterSettings } from '$/components/core/Editor/ColorSelectionSidebar/FilterSettings';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { intersect } from '$/utils/arrayUtils';

export const ColorSelectionSidebar = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const projectId = useInspirationEditorStore.useCurrentProjectId();
  const user = useAuthenticationStore.useUser();

  const { activeComponent } = useInspirationEditorStore(
    useShallow((s) => ({ activeComponent: s.getActiveComponent() })),
  );

  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  const componentTypes = activeComponent?.materialTypes ?? [];
  const material = activeComponent?.material;
  const initialMode =
    user != null ? intersect(componentTypes, material?.type ?? [])[0] : 'uni';

  return (
    <Stack gap='0' w='full'>
      <ShareSection px='4' py='2' projectId={projectId} type='inspiration' />

      <SidebarDivider />

      <SearchInput
        searchLabel={t('inspirationEditor.attributes.search')}
        hideSearchIcon
        h='7'
        mt='3'
        px='4'
        mb={!material ? '4' : '0'}
      />

      {material && (
        <Box px='4' pt='6' pb='4'>
          <CurrentMaterialSection material={material} />
        </Box>
      )}

      <SidebarDivider />

      {material && material.type.includes('uni') && (
        <>
          <Box px='4' pt='3' pb='4'>
            <FanPageSelector
              material={material}
              onColorChange={changeComponentMaterial}
            />
          </Box>
          <SidebarDivider />
        </>
      )}

      <Box px='4' pt='3' pb='4'>
        <FanSelectionFilter
          materialTypes={componentTypes}
          initialMode={initialMode}
        />
      </Box>

      <SidebarDivider borderColor='mobileStackDivider' />

      <MoreSelectionFilter />

      <Box px='4' pt='3' pb='4'>
        <ColorRepresentationSelection
          material={material}
          materialTypes={componentTypes}
          onColorChange={changeComponentMaterial}
        />
      </Box>

      <SidebarDivider borderColor='mobileStackDivider' />

      <Box px='4' pt='3' pb='4'>
        <FilterSettings initialMode={initialMode} />
      </Box>

      <SidebarDivider />

      {hasPermission('Favorites_Select') && (
        <>
          <Box px='4' pt='3' pb='4'>
            <FavoriteFolderList />
          </Box>
          <SidebarDivider />
        </>
      )}

      <Box px='4' pt='3' pb='8'>
        <ExportSection mode='inspiration' />
      </Box>
    </Stack>
  );
};
