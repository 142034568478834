import { object, string, array, z, literal, union, number } from 'zod';

import { endpointFactory } from '$/services/endpoints';
import { mapUsesToType } from '$/services/mapper/uses';

const normalizeValue = (value: string) => {
  if (value === '') return '';
  if (value.indexOf('_') === -1) return value;

  return value
    .substring(0, value.indexOf('_'))
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
};

export const MaterialSchema = object({
  translated: object({
    info: string(),
    producer: string(),
    collection: string(),
    SEARCH_CRITERIA_7: string(),
  }),
  id: string().nullable(),
  collection: string(),
  producer: string(),
  category: string(),
  color: string(),
  uses: string(),
  mode: string(),
  info: string(),
  pro_or_free: z
    .string()
    .nullable()
    .transform((s) => (s === 'free' ? 'free' : 'pro')),
  unique_key: string(),
  texture_filename: string().optional(),
  SEARCH_CRITERIA_8: string(),
  SEARCH_CRITERIA_16: string(),
  SEARCH_CRITERIA_INT_6: string(),
  SEARCH_CRITERIA_INT_7: string(),
  SEARCH_CRITERIA_INT_8: string(),
  SEARCH_CRITERIA_INT_10: string(),
}).transform((material) => {
  const hasTexture =
    material.texture_filename && material.texture_filename !== '';

  const isMaterial =
    hasTexture && (material.uses.includes('7') || material.uses.includes('8'));

  if (!material.id) return;

  return {
    color: `#${material.SEARCH_CRITERIA_8}`,
    id: material.id,
    uses: material.uses,
    type: mapUsesToType(material.uses),
    producer: normalizeValue(material.translated.producer),
    collection: normalizeValue(material.translated.collection),
    rawCollection: material.collection,
    rawProducer: material.producer,
    rawColor: material.color.split(','),
    info: material.translated.info,
    uniqueKey: material.unique_key,
    brightness: !isMaterial ? parseInt(material.SEARCH_CRITERIA_INT_6) : -1,
    family: material.translated.SEARCH_CRITERIA_7,
    url: ({ width = '500', height = '500' } = {}) =>
      hasTexture
        ? endpointFactory.materialImageUrl({
            id: material.id!,
            width,
            height,
          })
        : undefined,
    position: {
      page:
        material.SEARCH_CRITERIA_INT_7 != null
          ? parseInt(material.SEARCH_CRITERIA_INT_7)
          : Number.MAX_SAFE_INTEGER,
      pagePosition:
        material.SEARCH_CRITERIA_INT_8 != null
          ? parseInt(material.SEARCH_CRITERIA_INT_8)
          : Number.MAX_SAFE_INTEGER,
    },
    LCH: material.SEARCH_CRITERIA_16.replace(/[^·|\d]/g, '').replace(/·/g, ' '),
    isProMaterial: material.pro_or_free === 'pro',
  };
});

export const MaterialsSchema = object({
  status: union([literal(1), literal(0)]),
  resultcnt: number().optional(),
  resultlist: array(MaterialSchema).optional(),
}).transform((data) => {
  if (data.resultlist == null || data.resultlist.length === 0)
    return { materialCount: 0, materials: [] };

  return {
    materialCount: data.resultcnt!,
    materials: data.resultlist.filter(
      (m): m is NonNullable<z.infer<typeof MaterialSchema>> => !!m,
    ),
  };
});

export type Materials = z.infer<typeof MaterialsSchema>;
export type Material = Materials['materials'][number];
