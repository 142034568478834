import { queryOptions } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { STANDARD_STALE_TIME } from '$/services/fetcher';
import {
  getAllProjects,
  getEditorProject,
  getInspirationProject,
} from '$/services/usecases/projects';

export const projectsQuery = (
  { enabled }: { enabled: boolean } = { enabled: true },
) =>
  queryOptions({
    queryKey: ['projects', useAuthenticationStore.getState().user?.id, enabled],
    queryFn: () => getAllProjects(),
    staleTime: STANDARD_STALE_TIME,
    enabled,
  });

export const inspirationProjectQuery = (projectId: string, enabled = true) =>
  queryOptions({
    queryKey: [
      'project-inspiration',
      useAuthenticationStore.getState().user?.id,
      projectId,
    ],
    enabled,
    queryFn: () => getInspirationProject(projectId),
    staleTime: 0,
  });

export const editorProjectQuery = (projectId: string, enabled = true) =>
  queryOptions({
    queryKey: [
      'project-editor',
      useAuthenticationStore.getState().user?.id,
      projectId,
    ],
    enabled,
    queryFn: () => getEditorProject(projectId),
    staleTime: 0,
  });
