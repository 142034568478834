import { backendEndpoints } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { GeneralBackendResponseObjectSchema } from '$/services/mapper/authentication';

export type Feedback = {
  issue?: string;
  idea?: string;
  email?: string;
};

export const sendFeedback = async (feedback: Feedback) => {
  return await fetcher(backendEndpoints.FEEDBACK_URL, {
    body: feedback,
    method: 'POST',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};
