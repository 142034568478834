import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { NewProjectCard } from '$/components/core/Projects/components/Cards/NewProjectCard';
import { UpgradeAccountCard } from '$/components/core/Projects/components/Cards/UpgradeAccountCard';
import { ProjectCard } from '$/components/core/Projects/ProjectCard';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { useProjectStore } from '$/stores/useProjectStore';

export const ProjectRowItems = () => {
  const { hasPermission, canCreateMoreProjects, getProjectCountLimit } =
    useAuthorization();
  const itemLimit = useMaterialGridMaxItems();
  const isMobile = useIsMobile();

  const allProjects = useProjectStore.useProjects();

  const projects = allProjects
    .slice(0, getProjectCountLimit())
    .map((project) => <ProjectCard key={project.id} project={project} />);

  if (hasPermission('General_Project_Page')) {
    if (!canCreateMoreProjects()) {
      return (
        <>
          {projects.slice(0, Math.min(3, itemLimit))}
          <UpgradeAccountCard />
        </>
      );
    }
    if (allProjects.length < 4) {
      return (
        <>
          {projects}
          {!isMobile && <NewProjectCard />}
        </>
      );
    }
    if (allProjects.length >= itemLimit) {
      return projects.slice(0, itemLimit);
    }
  }

  if (isMobile) {
    return (
      <>
        {projects}
        {canCreateMoreProjects() && <UpgradeAccountCard />}
      </>
    );
  }

  return (
    <>
      {projects}
      {canCreateMoreProjects() ? <NewProjectCard /> : <UpgradeAccountCard />}
    </>
  );
};
