import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { MaterialCardContextMenu } from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';

interface Props {
  material?: Material;
  onClick?: () => void;
  materialType: MaterialType;
  hideFavoriteButton?: boolean;
}

export const ListPresentationItem: FC<Props> = ({
  material,
  onClick,
  materialType,
  hideFavoriteButton = false,
}) => {
  if (!material || !onClick)
    return <Skeleton w='full' h='90px' borderRadius='8px' />;

  return (
    <HStack
      pos='relative'
      align='stretch'
      gap='6'
      overflow='hidden'
      w='full'
      border='1px solid'
      borderColor='border'
      borderRadius='8px'
      cursor='pointer'
      onClick={onClick}
    >
      <Box
        w='32'
        minH='100%'
        bg={material.color}
        bgImage={material.url()}
        bgSize='cover'
        borderRight='1px solid'
        borderRightColor='border'
      />
      <VStack align='flex-start' py='4'>
        <HStack
          alignItems='flex-start'
          flexDir={{ base: 'column', sm: 'row' }}
          color='lighterText'
          fontSize='sm'
          fontWeight='bold'
        >
          <Text flexWrap='wrap'>
            {`${material.collection.toLocaleUpperCase()}, ${material.producer.toLocaleUpperCase()}`}
          </Text>
          {material.brightness >= 0 && (
            <HStack>
              <Icon icon='brightness' boxSize='14px' />
              <Text>{material.brightness}</Text>
            </HStack>
          )}
        </HStack>
        <Text color='text' fontSize='2xl' fontWeight='bold'>
          {material.info}
        </Text>
      </VStack>
      {!hideFavoriteButton && (
        <MaterialCardContextMenu
          editMode='favorite'
          material={material}
          materialType={materialType}
          bg='bodyBackground'
        />
      )}
    </HStack>
  );
};
