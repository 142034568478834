import { HStack, StackProps, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';

import { ColorLayerImage } from '$/pages/InspirationEditorPage/components/LayerSection/components/ColorLayerImage';
import { useHoverAndActiveState } from '$/pages/InspirationEditorPage/hooks/useHoverAndActiveState';
import {
  Layer,
  useInspirationEditorStore,
} from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props extends StackProps {
  layer: Layer;
}

export const ColorLayer: FC<Props> = ({ layer, ...props }) => {
  const changeComponent = useInspirationEditorStore.useChangeComponent();
  const getActiveHoverProps = useHoverAndActiveState(() => {
    changeComponent(layer.id);
  });

  const nameTranslationKey = `inspirationEditor.objects.${layer.uniqueName}`;

  return (
    <HStack cursor='pointer' {...getActiveHoverProps(layer.id)} {...props}>
      <HStack gap='1'>
        <ColorLayerImage material={layer.material} />
      </HStack>
      <Text fontSize='sm' lineHeight='16px'>
        {t(nameTranslationKey)}
      </Text>
    </HStack>
  );
};
