import { Box, useColorMode } from '@chakra-ui/react';
import { WheelEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useKeyPress } from '$/hooks/useKeyPress';
import { ModuleSectionContextMenu } from '$/pages/EditorPage/components/ModuleSectionContextMenu';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorModuleListener } from '$/pages/EditorPage/hooks/useEditorModuleListener';
import {
  MAXZOOMLEVEL,
  MINZOOMLEVEL,
  useEditorStore,
} from '$/pages/EditorPage/stores/useEditorStore';
import { editorEndpoints } from '$/services/endpoints';

export const ModuleSection = () => {
  const { colorMode } = useColorMode();
  const setEditorModule = useEditorStore.useSetEditorModule();
  const zoomPercent = useEditorStore.useZoomPercent();
  const { t } = useTranslation();
  const { moduleMessageListener } = useEditorModuleListener();
  const { selectAction, zoomPercentage, refreshBackgroundColor } =
    useEditorActions();

  const editorProject = useEditorStore.useEditorProject();
  const freeUserToken = useEditorStore.useFreeUserToken();

  useKeyPress(({ key, ctrl, shift }) => {
    if (ctrl && key === '0') {
      zoomPercentage(100);
    }
    if (key === '+') {
      const newZoomLevel = zoomPercent + 25;
      const zoomLevelInBounds = Math.min(MAXZOOMLEVEL, newZoomLevel);
      zoomPercentage(zoomLevelInBounds);
    }
    if (key === '-') {
      const newZoomLevel = zoomPercent - 25;
      const zoomLevelInBounds = Math.max(MINZOOMLEVEL, newZoomLevel);
      zoomPercentage(zoomLevelInBounds);
    }
    if (shift && key === '!') {
      selectAction(ModuleSendSelectAction.ZoomInnerfit);
    }
  }, []);

  const onWheel: WheelEventHandler = (event) => {
    if (event.deltaY > 0) {
      const newZoomLevel = zoomPercent - 25;
      const zoomLevelInBounds = Math.max(MINZOOMLEVEL, newZoomLevel);
      zoomPercentage(zoomLevelInBounds);
    } else if (event.deltaY < 0) {
      const newZoomLevel = zoomPercent + 25;
      const zoomLevelInBounds = Math.min(MAXZOOMLEVEL, newZoomLevel);
      zoomPercentage(zoomLevelInBounds);
    }
  };

  useEffect(() => {
    window.addEventListener('message', moduleMessageListener);

    return () => {
      window.removeEventListener('message', moduleMessageListener);
    };
  }, [moduleMessageListener]);

  useEffect(() => {
    refreshBackgroundColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorMode]);

  const focusIframe = () => {
    const activeTag = document.activeElement?.tagName.toLocaleLowerCase();
    const inputType = document.activeElement?.getAttribute('type');
    if (activeTag === 'input' && !(inputType === 'checkbox')) {
      return;
    }
    document.getElementById('iframe')!.focus();
  };

  if (editorProject == null && freeUserToken == null) {
    return null;
  }

  return (
    <Box
      pos='fixed'
      w='100vw'
      h='100vh'
      id='imgBox'
      onContextMenu={(e) => e.preventDefault()}
      onWheel={onWheel}
    >
      <ModuleSectionContextMenu />
      <iframe
        id='iframe'
        onMouseEnter={focusIframe}
        src={editorEndpoints.EDITOR_SRC}
        ref={(node) => setEditorModule(node)}
        title={t('editor.moduleTitle')}
        width='100%'
        height='100%'
      />
    </Box>
  );
};
