import { GridItem, SimpleGrid, Stack, StackProps } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { outlined } from '$/components/common/Accessibility';
import { For } from '$/components/common/Flow/For';
import { Icon } from '$/components/common/Icon';
import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { useCollectionStore } from '$/stores/useCollectionStore';

export const ColorFilter: FC<StackProps> = (props) => {
  const { t } = useTranslation();

  const filter = useCollectionStore.useFilter();

  const isFilterActive = useCollectionStore.useIsFilterActive();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();

  const [loading, setLoading] = useState('');

  const colorFilter = filter?.general.find(
    (item) => item.searchName === 'color',
  );

  if (colorFilter == null || colorFilter.items.length === 0) return;

  return (
    <Stack gap='3' {...props}>
      <FilterHeader
        header={t('inspirationEditor.filter.colorGroups')}
        prefix={<Icon icon='color_palette' w='4' />}
        isActive={true}
        isLoading={filter === undefined}
      />

      {colorFilter && (
        <SimpleGrid
          gap='2'
          templateRows={`repeat(${Math.ceil(colorFilter.items.length / 6)}, 1fr)`}
          columns={6}
        >
          <For each={colorFilter.items}>
            {(option, i) => (
              <GridItem gridRow={i < 2 ? '1' : Math.ceil((i - 1) / 6) + 1}>
                <ColorBox
                  materialColor={option.payload}
                  onClick={() => {
                    if (loading != '') return;

                    setLoading(option.intName);
                    void activateFilterGroup(
                      {
                        type: colorFilter.searchName,
                        option: option.intName,
                        displayName: option.displayName,
                        payload: option.payload,
                      },
                      true,
                    ).finally(() => setLoading(''));
                  }}
                  isLoading={loading === option.intName}
                  {...outlined({
                    visible: isFilterActive(
                      colorFilter.searchName,
                      option.intName,
                    ),
                  })}
                  cursor='pointer'
                  w='full'
                  h={undefined}
                  aspectRatio='1/1'
                />
              </GridItem>
            )}
          </For>
        </SimpleGrid>
      )}
    </Stack>
  );
};
