import {
  Box,
  Button,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FeedbackSection } from '$/components/core/FeedbackModal/components/FeedbackSection';
import { useFeedbackModal } from '$/hooks/useFeedbackModal';
import { useToast } from '$/hooks/useToast';
import { sendFeedback } from '$/services/usecases/feedback';

export const FeedbackModal = () => {
  const { t } = useTranslation();
  const [issue, setIssue] = useState('');
  const [idea, setIdea] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = useFeedbackModal.useIsOpen();
  const onClose = useFeedbackModal.useOnClose();
  const toast = useToast();

  const submittingDisabled = issue === '' && idea === '';

  const onCloseModal = () => {
    setIssue('');
    setIdea('');
    setEmail('');
    setIsLoading(false);
    onClose();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await sendFeedback({
      email,
      idea,
      issue,
    });

    if (response.isSuccessful) {
      toast(
        t('feedback.feedbackSentHeader'),
        'success',
        t('feedback.feedbackSentDescription'),
      );

      onCloseModal();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent
        maxW='594px'
        mx='0'
        my='6'
        p={{ base: '4', mobile: '10' }}
        color='text'
      >
        <HStack justify='space-between' pb='4'>
          <Text
            p='0'
            fontSize={{ base: 'h10', mobile: 'h7' }}
            fontWeight='bold'
          >
            {t('feedback.modalTitle')}
          </Text>

          <ModalCloseButton pos='static' />
        </HStack>

        <ModalBody p='0'>
          <VStack gap='6'>
            <Text fontSize='sm'>{t('feedback.modalDescription')}</Text>

            <FeedbackSection
              description={t('feedback.issueDescription')}
              icon='bug'
              title={t('feedback.issueTitle')}
              inputPlaceholder={t('feedback.issuePlaceholder')}
              inputValue={issue}
              onChange={setIssue}
            />
            <FeedbackSection
              description={t('feedback.ideaDescription')}
              icon='lightbulb'
              iconSize='6'
              title={t('feedback.ideaTitle')}
              inputPlaceholder={t('feedback.ideaPlaceholder')}
              inputValue={idea}
              onChange={setIdea}
            />
            <FeedbackSection
              additionalBlock={
                <Trans
                  i18nKey='feedback.helpDescription'
                  components={{
                    EmailLink: (
                      <Link
                        color='primaryButton.background'
                        fontWeight='bold'
                        href='mailto:spectrum@caparol.io'
                      >
                        Link
                      </Link>
                    ),
                  }}
                />
              }
              icon='question'
              title={t('feedback.helpTitle')}
            />
            <Box color='text'>
              <Text mb='2' fontSize='sm'>
                {t('feedback.emailDescription')}
              </Text>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('feedback.emailPlaceholder')}
                type='email'
                value={email}
              />
            </Box>
            <HStack justify='space-between' w='full' mt='4'>
              <Button
                fontSize='sm'
                isLoading={isLoading}
                onClick={onCloseModal}
                variant='text'
              >
                {t('general.cancel')}
              </Button>
              <Button
                fontSize='sm'
                isDisabled={submittingDisabled}
                isLoading={isLoading}
                onClick={onSubmit}
              >
                {t('feedback.sendButton')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
