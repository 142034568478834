import { Button, Center, HStack, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  title: string;
  onClick: VoidFunction;
  additional?: ReactNode;
}

export const NavbarButton: FC<Props> = ({
  icon,
  onClick,
  title,
  additional,
}) => {
  return (
    <Button
      w='full'
      px='4'
      py='3'
      borderRadius='0'
      onClick={onClick}
      variant='ghost'
    >
      <HStack gap='4' w='full'>
        <Center w='24px' h='24px'>
          {icon}
        </Center>
        <Text
          display={{ base: 'block', mobile: 'none', sm: 'block' }}
          color='text'
          fontSize='md'
          fontWeight='bold'
        >
          {title}
        </Text>
        {additional != null && additional}
      </HStack>
    </Button>
  );
};
