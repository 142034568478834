import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const SizeSection = () => {
  const { t } = useTranslation();

  const { width, height } = useEditorStore.useColorChecker();
  const { setColorCheckerSettings } = useEditorActions();

  return (
    <Stack w='full' mt='3'>
      <SectionTitle title={t('editor.dimensions.title')} icon='size' />
      <EditorNumberInput
        text={t('editor.dimensions.width')}
        initialValue={width}
        onChange={(val) => setColorCheckerSettings({ width: val })}
        minValue={1}
      />
      <EditorNumberInput
        text={t('editor.dimensions.height')}
        initialValue={height}
        onChange={(val) => setColorCheckerSettings({ height: val })}
        minValue={1}
      />
    </Stack>
  );
};
