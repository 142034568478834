import { VStack, Divider, Text, Checkbox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActiveColorOptions } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions';
import { BrushOptions } from '$/pages/EditorPage/components/AttributesSection/components/BrushOptions';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import {
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const RetouchColorSidebar = () => {
  const { t } = useTranslation();

  const workMode = useEditorStore.useWorkMode();
  const limitToMask = useEditorStore.useLimitToMask();

  const colorBrushSize = useEditorStore.useRetouchPaintSize();
  const colorBrushType = useEditorStore.useRetouchPaintType();
  const patternBrushSize = useEditorStore.useRetouchPatternSize();
  const patternBrushType = useEditorStore.useRetouchPatternType();

  const { setBrushSize, setLimitToMask } = useEditorActions();

  const getRetouchAttributes = () => {
    if (workMode === 'retouchPaint') {
      return {
        sizeAction: ModuleSendBrushSizeAction.RetouchPaintSize,
        shapeAction: ModuleSendBrushShapeAction.RetouchPaintType,
        brushSize: colorBrushSize,
        brushType: colorBrushType,
      };
    }

    return {
      sizeAction: ModuleSendBrushSizeAction.PatternBrushSize,
      shapeAction: ModuleSendBrushShapeAction.PatternBrushShape,
      brushSize: patternBrushSize,
      brushType: patternBrushType,
    };
  };

  const { sizeAction, shapeAction, brushSize, brushType } =
    getRetouchAttributes();

  return (
    <VStack
      w='full'
      fontSize='sm'
      divider={<Divider my='4 !important' borderColor='border' />}
    >
      <TitleSection title={t('editor.retouchColor.title')} />
      <ActiveColorOptions />
      {workMode != 'retouchGradient' && (
        <BrushOptions
          sizeAction={sizeAction}
          shapeAction={shapeAction}
          brushSize={brushSize}
          setBrushSize={setBrushSize}
          brushType={brushType}
        />
      )}
      <Checkbox
        checked={limitToMask}
        onChange={(e) => setLimitToMask(e.target.checked)}
        variant='editor'
      >
        <Text color='lighterText' fontSize='sm' fontWeight='bold'>
          {t('editor.retouchColor.limitToArea')}
        </Text>
      </Checkbox>
    </VStack>
  );
};
