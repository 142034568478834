import { Button } from '@chakra-ui/react';
import { FC } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';

interface Props {
  onClick: () => void;
  text: string;
  icon?: AvailableIcons;
  isDisabled?: boolean;
}

export const ContextMenuButton: FC<Props> = ({
  icon,
  onClick,
  text,
  isDisabled = false,
}) => (
  <Button
    gap='2'
    py='3'
    fontSize='sm'
    fontWeight='medium'
    bg='editor.deletePopoverBg'
    _first={{
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
    }}
    _last={{
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    }}
    isDisabled={isDisabled}
    leftIcon={icon ? <Icon icon={icon} /> : undefined}
    onClick={onClick}
    size='sm'
    variant='secondary'
  >
    {text}
  </Button>
);
