import { editorEndpoints } from '$/services/endpoints';
import { veeuzeFetcher } from '$/services/fetcher';
import { validateToken } from '$/services/usecases/authentication';
import { ImageUploadSchema } from '$/services/usecases/editor/mapper/imageUpload';
import { convertToBase64 } from '$/utils/codeBase64';

export const uploadProjectImage = async (selectedFile: File | null) => {
  if (!selectedFile) return;

  await validateToken();

  const { token } = await veeuzeFetcher(editorEndpoints.EDITOR_IMAGE_UPLOAD, {
    method: 'POST',
    msg: 'Failed to upload project image',
    body: { image: await convertToBase64(selectedFile) },
    parser: (data) => ImageUploadSchema.parse(data),
  });

  return token;
};
