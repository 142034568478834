import { Skeleton, Stack, StackProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { AdditionalFilterDropdown } from '$/components/core/Editor/ColorSelectionSidebar/Filter/AdditionalFilterDropdown';
import { CategorySelection } from '$/components/core/Editor/ColorSelectionSidebar/Filter/CategorySelection';
import { MultiFilterDropdown } from '$/components/core/Editor/ColorSelectionSidebar/Filter/MultiFilterDropdown';
import { SingleFilterDropdown } from '$/components/core/Editor/ColorSelectionSidebar/Filter/SingleFilterDropdown';
import { MaterialType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { repeat } from '$/utils/arrayUtils';
import { randomPlaceholder } from '$/utils/stringUtils';

interface Props extends StackProps {
  materialTypes?: MaterialType[];
  initialMode: MaterialType;
}

const LoadingFanSelectionFilter: FC<Props> = ({ initialMode }) => {
  const loadingFilter: Record<MaterialType, number> = {
    uni: 2,
    wall: 4,
    floor: 4,
    facade: 4,
  };

  return repeat(loadingFilter[initialMode]).map((group) => (
    <Stack key={group} gap='1'>
      <Skeleton w='fit-content' fontSize='xs' lineHeight='16px'>
        {randomPlaceholder(group, 10, 15)}
      </Skeleton>
      <Skeleton h='6' fontSize='xs' borderRadius='4'>
        {randomPlaceholder(group, 10, 15)}
      </Skeleton>
    </Stack>
  ));
};

export const FanSelectionFilter: FC<Props> = ({
  materialTypes,
  initialMode,
  ...props
}) => {
  const { t } = useTranslation();
  const filter = useCollectionStore.useFilter();

  return (
    <Stack {...props}>
      <AttributeHeader
        label={t('inspirationEditor.attributes.filterSettings.title')}
        icon={<Icon icon='filter' boxSize='4' />}
        description={t('inspirationEditor.attributes.filterSettings.info')}
      />

      <CategorySelection
        materialTypes={materialTypes}
        initialMode={initialMode}
      />

      <Stack gap='3'>
        {!filter && <LoadingFanSelectionFilter initialMode={initialMode} />}

        {filter &&
          filter.general
            .filter((group) => group.searchName !== 'color')
            .map((group) => (
              <Stack key={group.searchName} gap='1'>
                <Text fontSize='xs' lineHeight='16px'>
                  {t(group.displayNameKey)}
                </Text>
                {group.selectionFilter === 'single' ? (
                  <SingleFilterDropdown filter={group} />
                ) : (
                  <MultiFilterDropdown filter={group} />
                )}
              </Stack>
            ))}

        {filter && filter.additional.length && (
          <Stack gap='1'>
            <Text fontSize='xs' lineHeight='16px'>
              {t('inspirationEditor.filter.moreFilter')}
            </Text>
            <AdditionalFilterDropdown filter={filter?.additional} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
