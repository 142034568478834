import { Grid } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { materialTemplateColumn } from '$/theme/utils/templateColumn';

interface Props {
  children: ReactNode;
}

export const DashboardSectionRow: FC<Props> = ({ children }) => {
  return (
    <Grid
      flexWrap='wrap'
      gap={{ base: '3', md: '6' }}
      templateColumns={materialTemplateColumn()}
    >
      {children}
    </Grid>
  );
};
