import {
  Box,
  Button,
  Stack,
  StackDivider,
  VStack,
  Text,
  HStack,
  DrawerBody,
  DrawerHeader,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { BrightnessFilterMenuItem } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessFilterMenuItem';
import { MobileFilter } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilter';
import { FilterSkeleton } from '$/components/core/Collection/CollectionFilter/FilterSkeleton';
import { useCollectionViewOptions } from '$/hooks/useCollectionViewOptions';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { TooltipLabel } from '$/pages/DashboardPages/components/TooltipLabel';
import { FilterType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  type: FilterType;
  closeFilterDrawer: () => void;
  setSubFilter: (filterSearchName: string) => void;
  amount: number | null;
}

export const MobileMainFilterPage: FC<Props> = ({
  type,
  closeFilterDrawer,
  setSubFilter,
  amount,
}) => {
  const { t } = useTranslation();
  const possibleFilter = useCollectionStore.useFilter();
  const filterGroups = useCollectionStore.useFilterGroups();
  const resetFilters = useCollectionStore.useResetFilter();
  const setBrightnessFilter = useCollectionStore.useSetBrightnessFilter();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const viewModeOptions = useCollectionViewOptions(type);
  const activeViewMode = useCollectionStore.useSelectedViewMode();
  const setViewMode = useCollectionStore.useSetSelectedViewMode();
  const [brightness, setBrightness] = useState([
    brightnessFilter.from,
    brightnessFilter.to,
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeFilterDrawer(),
  });

  const getFilterPlaceholder = (filterSearchName: string) => {
    switch (filterSearchName) {
      case 'producer':
        return t('inspirationEditor.filter.producerDefault');
      case 'collection':
        return t('inspirationEditor.filter.collectionDefault');
      default:
        return undefined;
    }
  };

  const updateBrightnessFilter = (brightness: number[]) => {
    setBrightness(brightness);
    setBrightnessFilter({ from: brightness[0], to: brightness[1] });
  };

  const isAnyFilterActive =
    filterGroups.length > 0 ||
    brightnessFilter.from !== 0 ||
    brightnessFilter.to !== 100;

  const onResetFilter = async () => {
    setBrightness([0, 100]);
    await resetFilters();
  };

  const filters = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ].filter((filter) => filter.searchName !== 'collection');

  return (
    <Box>
      <DrawerHeader {...swipeEvents}>
        <HStack alignItems='baseline' justify='space-between'>
          <Text fontSize='md'>
            {t('dashboard.collection.filter.mobile.filterHeader')}
          </Text>
          <Button
            fontSize='sm'
            onClick={() => closeFilterDrawer()}
            variant='text'
          >
            {t('dashboard.collection.filter.mobile.cancelButton')}
          </Button>
        </HStack>
      </DrawerHeader>

      <DrawerBody>
        <VStack
          align='flex-start'
          divider={<StackDivider mt='1' color='mobileStackDivider' />}
          spacing={4}
        >
          {viewModeOptions.length > 0 && (
            <Box w='full'>
              <Text mb='3' color='lighterText' fontSize='sm' fontWeight='bold'>
                {t('dashboard.collection.displayOptions')}
              </Text>
              <HStack gap='2'>
                {viewModeOptions.map((option) => {
                  const isActive = activeViewMode === option.name;
                  return (
                    <IconButton
                      key={option.name}
                      color={
                        isActive ? 'primaryButton.activeBackground' : 'text'
                      }
                      bg={
                        isActive
                          ? 'primaryButton.lightBackground'
                          : 'transparent'
                      }
                      border='1px solid'
                      borderColor={isActive ? 'form.activeBorder' : 'border'}
                      borderRadius='4px'
                      aria-label=''
                      icon={<Icon icon={option.name} height='16px' />}
                      onClick={() => setViewMode(option.name)}
                      size='sm'
                      variant='text'
                    />
                  );
                })}
              </HStack>
            </Box>
          )}
          {filters.map((filter) => (
            <Box key={filter.searchName} w='full'>
              <Text color='lighterText' fontSize='sm' fontWeight='bold'>
                {t(filter.displayNameKey)}
              </Text>
              {!isLoading && (
                <MobileFilter
                  filterSearchName={filter.searchName}
                  onSingleFilterSelected={setSubFilter}
                  setIsLoading={setIsLoading}
                  placeholder={getFilterPlaceholder(filter.searchName)}
                />
              )}
              {isLoading && <FilterSkeleton title={t(filter.displayNameKey)} />}
            </Box>
          ))}
          {type === 'uni' && (
            <Box w='full'>
              <HStack justify='space-between'>
                <Text color='lighterText' fontWeight='bold'>
                  {t('dashboard.collection.filter.brightness')}
                </Text>
                <Tooltip
                  color='text'
                  bg='background'
                  border='1px'
                  borderColor='neutral.50'
                  shadow='base'
                  hasArrow
                  label={t('inspirationEditor.filter.resetBrightness')}
                  openDelay={500}
                >
                  <span>
                    <IconButton
                      minW={0}
                      aria-label={t('inspirationEditor.filter.resetBrightness')}
                      icon={<Icon icon='refresh' w='10px' />}
                      isDisabled={brightness[0] === 0 && brightness[1] === 100}
                      onClick={() => updateBrightnessFilter([0, 100])}
                      variant='text'
                    />
                  </span>
                </Tooltip>
              </HStack>

              <BrightnessFilterMenuItem
                value={brightness}
                setValue={updateBrightnessFilter}
              />
              <Stack gap='4' p='3'>
                <TooltipLabel
                  label={t('dashboard.collection.whatIsBrightness')}
                  info={t('dashboard.collection.brightnessExplanation')}
                />
              </Stack>
            </Box>
          )}
          <HStack justify='space-between' gap='4' w='100%'>
            <Button
              h='32px'
              fontSize='sm'
              isDisabled={!isAnyFilterActive}
              isLoading={isLoading}
              onClick={onResetFilter}
              variant='text'
            >
              {t('dashboard.collection.resetFilter')}
            </Button>
            <Button
              px='6'
              py='3'
              fontSize='sm'
              isLoading={isLoading}
              onClick={closeFilterDrawer}
            >
              {t('dashboard.collection.resultsAmount.showAmount', {
                amount,
              })}
            </Button>
          </HStack>
        </VStack>
      </DrawerBody>
    </Box>
  );
};
