import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  ComponentType,
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { TypedToOptions } from '$/components/core/StyledLink';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { useWindowSize } from '$/hooks/useWindowSize';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  headline: string;
  type: MaterialType;
  RowWrapper: ComponentType<{ children: ReactNode }>;
  hideFavoriteButtons?: boolean;
  minCardAmount?: number;
  cardLimit?: number;
  showAllUrl?: TypedToOptions;
  wrapperRef?: RefObject<HTMLDivElement>;
  onResult?: (count: number, type: MaterialType) => void;
  onShowMoreClick?: () => void;
  onSelect: (material: Material) => void;
}

export const MaterialRow: FC<Props> = ({
  headline,
  showAllUrl,
  type,
  wrapperRef,
  RowWrapper,
  onShowMoreClick,
  hideFavoriteButtons = false,
  minCardAmount = 1,
  cardLimit = 100,
  onResult = () => {},
  onSelect,
}) => {
  const filter = useCollectionStore.useFilterGroups();

  const materialWrapperRef = useRef<HTMLDivElement>(null);

  const { hasPermission } = useAuthorization();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();
  const query = useMaterialSearchStore.useQuery();

  const isDemoSection =
    type !== 'uni' && !hasPermission('View_All_Collections');

  const hasUser = useAuthenticationStore.useUser()?.id != null;

  const { data, isLoading, isFetching } = useQuery(
    materialsQuery(
      { filter, limit: 10, type: [type], demo: isDemoSection, query },
      undefined,
      undefined,
      hasUser,
    ),
  );

  useEffect(() => {
    data && onResult(data.materialCount, type);
  }, [data, onResult, type]);

  useWindowSize();

  const materialCardLimit = Math.max(
    minCardAmount,
    Math.min(cardLimit, useMaterialGridMaxItems()),
  );

  const wrapperHeaderHeight = 150;
  const sectionHeaderHeight = (36 + 24) * 3;
  const gridGap = 24;

  const wrapperHeight = wrapperRef?.current?.offsetHeight;
  const cardHeight = materialWrapperRef.current?.offsetHeight;

  const possibleCards =
    wrapperHeight && cardHeight
      ? Math.floor(
          (wrapperHeight - sectionHeaderHeight - wrapperHeaderHeight) /
            (cardHeight + gridGap),
        )
      : undefined;

  const rowsPerSection = possibleCards
    ? Math.max(1, Math.ceil(possibleCards / 3))
    : undefined;

  const visibleCards =
    rowsPerSection && materialCardLimit
      ? materialCardLimit * rowsPerSection
      : undefined;

  return (
    <Show when={isLoading || (data && data.materials.length > 0)}>
      <SectionHeadline
        headline={headline}
        showAllUrl={!isDemoSection ? showAllUrl : undefined}
        onShowMoreClick={onShowMoreClick}
      >
        <RowWrapper>
          <For
            each={
              data?.materials.slice(0, visibleCards ?? materialCardLimit) ?? []
            }
            isLoading={isLoading || isFetching}
            empty={() => null}
            fallback={() =>
              repeat(materialCardLimit ?? 0).map((key) => (
                <LoadingMaterialCard key={key} />
              ))
            }
          >
            {(material, index) => (
              <Box
                key={index}
                ref={index === 0 ? materialWrapperRef : undefined}
              >
                <MaterialCard
                  editMode={hideFavoriteButtons ? 'none' : undefined}
                  material={material}
                  onClick={() => {
                    if (!isDemoSection) onSelect(material);
                    else openAuthenticationModal('versionComparison');
                  }}
                  materialType={type}
                />
              </Box>
            )}
          </For>
        </RowWrapper>
      </SectionHeadline>
    </Show>
  );
};
