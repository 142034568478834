import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const PositionSection = () => {
  const { t } = useTranslation();

  const { x, y } = useEditorStore.useColorChecker();
  const { setColorCheckerSettings } = useEditorActions();

  return (
    <Stack w='full' px='4' pb='4'>
      <SectionTitle title={t('editor.position.title')} icon='position' />
      <EditorNumberInput
        text='X'
        initialValue={x}
        onChange={(val) => setColorCheckerSettings({ x: val })}
        minValue={1}
      />
      <EditorNumberInput
        text='Y'
        initialValue={y}
        onChange={(val) => setColorCheckerSettings({ y: val })}
        minValue={1}
      />
    </Stack>
  );
};
