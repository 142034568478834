import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '$/hooks/useWindowSize';
import { useGeneralStore } from '$/stores/useGeneralStore';

export const TurnScreenModal = () => {
  const { t } = useTranslation();

  const hasClosedTurnScreenModal =
    useGeneralStore.useHasClosedTurnScreenModal();

  const { width } = useWindowSize();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (width >= 744 && width <= 1024 && !hasClosedTurnScreenModal) onOpen();
    else if (isOpen) onClose();
  }, [width, isOpen, hasClosedTurnScreenModal, onOpen, onClose]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' w='320px' p='4'>
        <Text fontSize='sm'>{t('general_editor.turnDevice')}</Text>
        <Button
          p='3'
          color='lighterText'
          fontSize='sm'
          onClick={() => {
            useGeneralStore.setState({ hasClosedTurnScreenModal: true });
            onClose();
          }}
          variant='ghost'
        >
          {t('general.cancel')}
        </Button>
      </ModalContent>
    </Modal>
  );
};
