import { Grid } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { buttonTemplateColumn } from '$/theme/utils/templateColumn';

interface Props {
  children: ReactNode;
}

export const DashboardButtonRow: FC<Props> = ({ children }) => {
  return (
    <Grid
      flexWrap='wrap'
      gap={{ base: '3', md: '6' }}
      templateColumns={{
        ...buttonTemplateColumn(),
        mobile: 'repeat(1, minmax(1px, 1fr))',
        sm: 'repeat(3, minmax(1px, 1fr))',
      }}
    >
      {children}
    </Grid>
  );
};
