import { Box, HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { getEditorObjectIcon } from '$/pages/EditorPage/utils/getObjectItem';

interface Props {
  isActive: boolean;
  componentIndex: number;
}

export const ComponentBitmapItem: FC<Props> = ({
  isActive,
  componentIndex,
}) => {
  const { t } = useTranslation();
  const { selectComponent, selectAction } = useEditorActions();

  return (
    <HStack
      as='span'
      gap='1'
      h='10'
      pl='7'
      bg={isActive ? 'secondaryBackground' : 'transparent'}
      onClick={() => {
        selectComponent(componentIndex);
        selectAction(ModuleSendSelectAction.SelectPoly);
      }}
    >
      {getEditorObjectIcon('bitmap')}

      <Box
        as='span'
        pl='1'
        color='editor.objectText'
        fontSize='sm'
        userSelect='none'
      >
        {t('editor.bitmapLayer')}
      </Box>
    </HStack>
  );
};
