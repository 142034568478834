import { FC, useEffect, useRef } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';

import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useKeyPress } from '$/hooks/useKeyPress';
import { useWindowSize } from '$/hooks/useWindowSize';
import { ZoomableImage } from '$/pages/InspirationEditorPage/components/ImageSection/components/ZoomableImage';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  id: string;
}

export const ImageSection: FC<Props> = ({ id }) => {
  const isMobile = useIsMobile({ sm: true });

  const zoomIn = useActionBarStore.useZoomIn();
  const zoomOut = useActionBarStore.useZoomOut();
  const resetZoom = useActionBarStore.useResetZoom();

  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();

  const ref = useRef<ReactZoomPanPinchRef>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    ref.current?.centerView(isMobile ? 3 : 1, 0);
  }, [width, isMobile]);

  useKeyPress(({ key, ctrl }) => {
    if (ctrl && key === '0') return resetZoom();
    if (key === '+') return zoomIn();
    if (key === '-') return zoomOut();
  }, []);

  return (
    <TransformWrapper
      minScale={0.025}
      centerOnInit
      ref={ref}
      disabled={perspectiveFullscreen}
    >
      <TransformComponent
        wrapperStyle={{
          width: '100vw',
          height: '100dvh',
          position: 'absolute',
        }}
      >
        <ZoomableImage id={id} />
      </TransformComponent>
    </TransformWrapper>
  );
};
