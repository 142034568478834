import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { ActionCard } from '$/components/core/Projects/components/Cards/ActionCard';

interface Props {
  type?: 'createProject' | 'createFavorite' | 'loginFavorite';
}

export const AuthActionCard: FC<Props> = ({ type = 'createProject' }) => {
  const { t } = useTranslation();

  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  const getLowerText = () => {
    switch (type) {
      case 'createProject':
        return t('dashboard.welcome.createOwnProjects');
      case 'loginFavorite':
      case 'createFavorite':
        return t('dashboard.welcome.createFavorites');
    }
  };

  const getUpperText = () => {
    switch (type) {
      case 'createProject':
      case 'createFavorite':
        return t('dashboard.welcome.createAccount');
      case 'loginFavorite':
        return t('dashboard.header.signIn');
    }
  };

  return (
    <ActionCard
      icon={<Icon icon='account' h='32px' w='32px' />}
      headerText={getUpperText()}
      lowerText={getLowerText()}
      background='dashboard.createAccountCard.background'
      color='dashboard.createAccountCard.color'
      as='button'
      aspectRatio={{ base: '6/2', sm: '3/2' }}
      onClick={() =>
        onOpenAuthenticationModal(
          type === 'loginFavorite' ? 'login' : 'register',
        )
      }
    />
  );
};
