import { Badge, Box, Flex, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useFeedbackModal } from '$/hooks/useFeedbackModal';

export const Links = () => {
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();
  const onOpenFeedbackModal = useFeedbackModal.useOnOpen();

  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  return (
    <Flex align='center' gap='3'>
      <Badge
        h='5'
        px='1'
        py='0.5'
        color='primaryButton.color'
        lineHeight='4'
        textTransform='none'
        bg='primaryButton.background'
      >
        Beta
      </Badge>

      <Box w='2px' h='50%' bg='header.divider' />

      <IconButton
        minW='0'
        aria-label='Feedback'
        icon={<Icon icon='message' />}
        onClick={onOpenFeedbackModal}
        variant='text'
      />

      <Box w='2px' h='50%' bg='header.divider' />

      {hasPermission('General_Login_Register') && (
        <GhostButton
          onClick={() => onOpenAuthenticationModal('login')}
          rightIcon={<Icon icon='arrow_right' width='13px' />}
          h='full'
          fontSize='sm'
        >
          {t('dashboard.header.signIn')}
        </GhostButton>
      )}
    </Flex>
  );
};
