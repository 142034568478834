import {
  Box,
  Collapse,
  SimpleGrid,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { outlined } from '$/components/common/Accessibility';
import { Icon } from '$/components/common/Icon';
import { CollectionViewOptionSelector } from '$/components/core/Collection/CollectionViewOptionSelector';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { MaterialListItemSkeleton } from '$/components/core/Collection/MaterialListItemSkeleton';
import { FanPresentation } from '$/components/core/Collection/MaterialPresentation/FanPresentation';
import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { ColorGridPanel } from '$/components/core/Editor/ColorSelectionSidebar/ColorGridPanel';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { LoadingColorBox } from '$/components/core/Editor/ColorSelectionSidebar/LoadingColorBox';
import { useIntersectionObserver } from '$/hooks/useIntersectionObserver';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { infiniteMaterialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  material: Material | null | undefined;
  materialTypes?: MaterialType[];
  onColorChange: (material: Material) => void;
  columnCount?: number;
  columnHeight?: string;
  isCollapsible?: boolean;
}

export const ColorRepresentationSelection: FC<Props> = ({
  material,
  materialTypes,
  onColorChange,
  columnCount = 6,
  columnHeight = '215px',
  isCollapsible = true,
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const filter = useCollectionStore.useFilterGroups();
  const activeViewMode = useCollectionStore.useSelectedViewMode();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const activeType = useCollectionStore.useActiveType();

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      infiniteMaterialsQuery({
        limit: 100,
        filter,
        brightnessFilter,
        type: activeType && activeType !== 'all' ? [activeType] : [],
      }),
    );

  const materials = data?.pages.flatMap((page) => page.materials);

  const observer = useIntersectionObserver(
    (intersecting) => intersecting && !isFetching && fetchNextPage(),
    {
      disabled: !data || isFetching || !hasNextPage || materials?.length === 0,
    },
  );

  const activeMaterialId = material?.uniqueKey;

  const getHeader = () => {
    switch (activeType) {
      case 'floor':
        return t('inspirationEditor.attributes.floorPresentation');
      case 'wall':
        return t('inspirationEditor.attributes.wallPresentation');
      case 'facade':
        return t('inspirationEditor.attributes.facadePresentation');
      default:
        return t('inspirationEditor.attributes.colorPresentation');
    }
  };

  const getIcon = () => {
    switch (activeType) {
      case 'floor':
        return <Icon icon='floor_material' boxSize='3' />;
      case 'wall':
        return <Icon icon='wallpaper' boxSize='4' />;
      case 'facade':
        return <Icon icon='facade_material' boxSize='3' />;
      default:
        return <Icon icon='inspiration_marker' boxSize='4' />;
    }
  };

  return (
    <Stack gap='0'>
      <Box onKeyDown={({ key }) => key === 'Enter' && onToggle()} tabIndex={0}>
        <FilterHeader
          header={getHeader()}
          isActive={isOpen}
          onClick={() => isCollapsible && onToggle()}
          prefix={getIcon()}
          isCollapsible={isCollapsible}
        />
      </Box>

      <Collapse in={isOpen}>
        <Box pt='1' pb='3'>
          <CollectionViewOptionSelector
            filterType={materialTypes?.includes('uni') ? 'uni' : 'all'}
          />
        </Box>

        <Stack h={columnHeight}>
          {activeViewMode === 'grid' && (
            <ColorGridPanel
              materials={materials ?? []}
              observer={observer}
              isLoading={!data || isLoading || isFetching}
              item={(material: Material) => (
                <ColorBox
                  onClick={() => onColorChange(material)}
                  materialColor={material.color}
                  w='full'
                  h={undefined}
                  image={material.url({ width: '30', height: '30' })}
                  aspectRatio='1/1'
                  border='1px solid'
                  borderColor='border'
                  {...outlined({
                    visible: material.uniqueKey === activeMaterialId,
                  })}
                />
              )}
              ItemWrapper={({ children }) => (
                <SimpleGrid
                  gap='2'
                  overflowY='auto'
                  p='1'
                  columns={columnCount}
                >
                  {children}
                </SimpleGrid>
              )}
              LoadingElement={() => <LoadingColorBox />}
            />
          )}
          {activeViewMode === 'list' && (
            <ColorGridPanel
              materials={materials ?? []}
              observer={observer}
              isLoading={!data || isLoading || isFetching}
              item={(material: Material, index: number) => (
                <MaterialListItem
                  id={index}
                  material={material}
                  showAsActive={material.uniqueKey === activeMaterialId}
                  onClick={() => onColorChange(material)}
                />
              )}
              ItemWrapper={({ children }) => <Stack>{children}</Stack>}
              LoadingElement={({ index }) => (
                <MaterialListItemSkeleton id={index} />
              )}
            />
          )}
          {activeViewMode === 'fan' && (
            <FanPresentation
              observer={observer}
              setActiveMaterial={onColorChange}
              materials={materials ?? []}
              type='uni'
              isLoading={!data || isLoading || isFetching}
              disableHovering={true}
              itemHeight='2rem'
              rowGap='2'
              borderRadius='4px'
              activeMaterialId={activeMaterialId}
            />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};
