import { Box, Stack, StackProps } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareSection';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderList';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { SceneDetailSection } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/SceneDetailSection';
import { EditorRoute } from '$/routes/Editor/EditorRoute';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { toUniqueArray } from '$/utils/arrayUtils';

export const OverviewSidebar: FC<StackProps> = (props) => {
  const { t } = useTranslation();
  const { projectId } = EditorRoute.useSearch();
  const components = useEditorStore.useComponents();
  const { hasPermission } = useAuthorization();
  const materialIds = toUniqueArray(
    components
      .map((component) => component.materialId)
      .filter((id) => id != null) as string[],
  );
  const { data: materialsResponse, isLoading } = useQuery(
    materialsQuery({ ids: materialIds }),
  );

  const colorItems =
    materialsResponse?.materials.filter(
      (material) => material.type.includes('uni') && !material?.url(),
    ) ?? [];

  const wallItems =
    materialsResponse?.materials.filter(
      (material) => material.type.includes('wall') && !!material?.url(),
    ) ?? [];

  const floorItems =
    materialsResponse?.materials.filter(
      (material) => material.type.includes('floor') && !!material?.url(),
    ) ?? [];

  const facadeItems =
    materialsResponse?.materials.filter(
      (material) => material.type.includes('facade') && !!material?.url(),
    ) ?? [];

  return (
    <Stack gap='0' {...props}>
      <ShareSection px='4' py='2' type='editor' projectId={projectId} />

      <SidebarDivider />

      <SceneDetailSection
        icon={<Icon icon='inspiration_marker' boxSize='4' />}
        label={t('inspirationEditor.attributes.usedColors')}
        isLoading={isLoading}
        items={colorItems}
      />

      {(colorItems.length > 0 || isLoading) && <SidebarDivider />}

      <SceneDetailSection
        icon={<Icon icon='wallpaper' boxSize='4' />}
        label={t('inspirationEditor.attributes.usedWalls')}
        isLoading={isLoading}
        items={wallItems}
      />

      {(wallItems.length > 0 || isLoading) && <SidebarDivider />}

      <SceneDetailSection
        icon={<Icon icon='floor_material' w='14px' />}
        label={t('inspirationEditor.attributes.usedFloors')}
        isLoading={isLoading}
        items={floorItems}
      />

      {(floorItems.length > 0 || isLoading) && <SidebarDivider />}

      <SceneDetailSection
        icon={<Icon icon='facade_material' w='14px' />}
        label={t('inspirationEditor.attributes.usedFacades')}
        isLoading={isLoading}
        items={facadeItems}
      />

      {(facadeItems.length > 0 || isLoading) && <SidebarDivider />}

      {hasPermission('Favorites_Select') && (
        <>
          <Box px='4' pt='3' pb='4'>
            <FavoriteFolderList />
          </Box>
          <SidebarDivider />
        </>
      )}

      <Box px='4' pt='3' pb='8'>
        <ExportSection mode='editor' />
      </Box>
    </Stack>
  );
};
