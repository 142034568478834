import { HStack, IconButton, StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { BackButton } from '$/pages/DashboardPages/pages/Collection/pages/components/BackButton';

interface Props extends StackProps {
  onActionClick?: () => void;
}

export const StickySubCollectionHeader: FC<Props> = ({
  onActionClick,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <HStack
      pos='absolute'
      zIndex='30'
      justifyContent='space-between'
      display='flex'
      w='full'
      bg='background'
      borderBottom='1px solid'
      borderBottomColor='border'
      transition='top 0.3s ease-in-out'
      {...props}
    >
      <BackButton />
      <IconButton
        aria-label={t('general.backToTop')}
        icon={<Icon icon='arrow_right' w='15px' transform='rotate(-90deg)' />}
        onClick={onActionClick}
        variant='text'
      />
    </HStack>
  );
};
