import { Icon as ChakraIcon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

import { iconOptions } from '$/components/common/Icon/IconOptions';

export type AvailableIcons = (typeof iconOptions)[number];

interface Props extends IconProps {
  icon: AvailableIcons;
  iconPath?: string;
}

export const Icon: FC<Props> = ({
  icon,
  iconPath = `${import.meta.env.BASE_URL}/icons/icons.svg`.replace('//', '/'),
  className = '',
  ...rest
}) => {
  return (
    <ChakraIcon
      className={className}
      w='20px'
      h='20px'
      fill='currentcolor'
      pointerEvents='none'
      {...rest}
    >
      <use xlinkHref={`${iconPath}#${icon}`} />
    </ChakraIcon>
  );
};
