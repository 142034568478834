import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useBlocker } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useSaveBeforeLeaveModalStore } from '$/pages/EditorPage/hooks/useSaveBeforeLeaveModalStore';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const SaveBeforeLeaveModal = () => {
  const { t } = useTranslation();
  const { saveProject } = useEditorActions();
  const isOpen = useSaveBeforeLeaveModalStore.useIsModalOpen();
  const onOpen = useSaveBeforeLeaveModalStore.useOnModalOpen();
  const hasDiscarded = useSaveBeforeLeaveModalStore.useHasDiscarded();
  const onCloseModal = useSaveBeforeLeaveModalStore.useOnModalClose();
  const components = useEditorStore.useComponents();
  const previousComponentState =
    useSaveBeforeLeaveModalStore.usePreviousComponentState();
  const discardedState = useSaveBeforeLeaveModalStore.useDiscardedState();
  const isLoading = useSaveBeforeLeaveModalStore.useIsLoading();
  const { hasPermission } = useAuthorization();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  const hasUnsavedChanges =
    JSON.stringify(components) !== previousComponentState;

  const changesAfterDiscard =
    hasDiscarded && discardedState !== JSON.stringify(components);

  const showModal = hasUnsavedChanges && (!hasDiscarded || changesAfterDiscard);

  const { status, proceed, reset } = useBlocker({
    condition: showModal,
  });

  useEffect(() => {
    if (status === 'blocked') {
      onOpen();
    }
  }, [onOpen, status]);

  const onClose = () => {
    useSaveBeforeLeaveModalStore.setState({
      hasDiscarded: true,
      discardedState: JSON.stringify(components),
    });
    onCloseModal();
    reset();
  };

  const onSave = () => {
    if (!hasPermission('Project_Save')) {
      onOpenAuthModal('login');
      reset();
      return;
    }

    useSaveBeforeLeaveModalStore.setState({ isLoading: true });

    saveProject();
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data === 'ProjectInformationSaved') {
        proceed();
      }
    });
  };

  return (
    <Modal
      closeOnOverlayClick={!isLoading}
      isCentered
      isOpen={isOpen && showModal}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w='20.125rem'>
        <ModalHeader>{t('editor.saveUnchangedChangesModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p='4'>
          <Text color='text' fontSize='sm'>
            {t('editor.saveUnchangedChangesModal.description')}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack justify='center' gap='2' w='full'>
            <Button
              fontSize='sm'
              isLoading={isLoading}
              onClick={proceed}
              variant='text'
            >
              {t('dashboard.authentication.loginToSave.close')}
            </Button>
            <Button fontSize='sm' isLoading={isLoading} onClick={onSave}>
              {t('editor.save')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
