import { HStack, Text, Spacer, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BrushShapeOptionButton } from '$/pages/EditorPage/components/AttributesSection/components/BrushShapeOptionButton';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { SliderInput } from '$/pages/EditorPage/components/AttributesSection/components/SliderInput';
import {
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { BrushType } from '$/services/usecases/editor/mapper/editorStatus';

interface BrushOptionsProps {
  sizeAction: ModuleSendBrushSizeAction;
  shapeAction: ModuleSendBrushShapeAction;
  brushSize: number;
  brushType: BrushType;
  setBrushSize: (action: ModuleSendBrushSizeAction, value: number) => void;
  minValue?: number;
  maxValue?: number;
  title?: string;
  shapeTitle?: string;
}

export const BrushOptions: FC<BrushOptionsProps> = ({
  sizeAction,
  shapeAction,
  brushSize,
  brushType,
  title,
  shapeTitle,
  setBrushSize,
  minValue = 1,
  maxValue = 100,
}) => {
  const { t } = useTranslation();

  return (
    <Stack gap='3' w='full' px='4'>
      <SectionTitle icon='brush' title={title ?? t('editor.brushSize')} />
      <Stack gap='4' w='full'>
        <SliderInput
          initialValue={brushSize}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(val) => setBrushSize(sizeAction, val)}
        />
        <HStack>
          <Text>{shapeTitle ?? t('editor.brushShape')}</Text>
          <Spacer />
          <BrushShapeOptionButton
            type='round'
            mode={shapeAction}
            activeType={brushType}
          />
          <BrushShapeOptionButton
            type='square'
            mode={shapeAction}
            activeType={brushType}
          />
        </HStack>
      </Stack>
    </Stack>
  );
};
