import { useColorMode } from '@chakra-ui/react';

import { Icon } from '$/components/common/Icon';
import { Switch } from '$/components/common/Switch';
import { trackContentInteraction } from '$/logger';

export const ThemeSwitcher = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isLightMode = colorMode === 'light';

  const onSwitchColorMode = () => {
    trackContentInteraction(
      'ClickButton',
      'MainMenu',
      'Themes',
      !isLightMode ? 'Light' : 'Dark',
    );
    toggleColorMode();
  };

  return (
    <Switch
      checked={isLightMode}
      onChange={onSwitchColorMode}
      knob={
        isLightMode ? (
          <Icon icon='light_mode' h='full' w='full' />
        ) : (
          <Icon icon='dark_mode' h='full' w='full' />
        )
      }
    />
  );
};
