import { Button, HStack, Heading, Stack } from '@chakra-ui/react';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { FilterTags } from '$/components/core/Collection/MaterialPresentation/DataSortingRow/components/FilterTags';
import { FavoriteGroup } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteGroup';
import { useGroupedFavorites } from '$/pages/DashboardPages/pages/Favorite/hooks/useGroupedFavorites';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

export const FavoriteFolderView = () => {
  const { t } = useTranslation();
  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const folders = useFavoriteStore.useFavoriteFolders();

  const {
    colorFavorites,
    floorFavorites,
    wallFavorites,
    facadeFavorites,
    isLoading,
  } = useGroupedFavorites(activeFolderId!);

  const currentFolder = folders.find((folder) => folder.id === activeFolderId);

  return (
    <>
      <HStack>
        <Button
          px='0'
          leftIcon={<Icon icon='arrow_right' transform='rotate(180deg)' />}
          onClick={() => setActiveFolderId(null)}
          variant='text'
        >
          {t('general.backToOverview')}
        </Button>
      </HStack>
      <Heading as='h1' mt='2' mb='4' fontSize='3xl'>
        {currentFolder?.name}
      </Heading>

      <FilterTags />

      <Stack gap='6' pt='6'>
        <DragDropContext onDragEnd={() => {}}>
          <FavoriteGroup
            headline={t('dashboard.collection.search.colorTones')}
            favorites={colorFavorites}
            isLoading={isLoading}
            materialType='uni'
            editMode='favoriteExtended'
            isDragDisabled={true}
          />
          <FavoriteGroup
            headline={t('dashboard.collection.search.wallCoverings')}
            favorites={wallFavorites}
            isLoading={isLoading}
            materialType='wall'
            editMode='favoriteExtended'
            isDragDisabled={true}
          />
          <FavoriteGroup
            headline={t('dashboard.collection.search.floorCoverings')}
            favorites={floorFavorites}
            isLoading={isLoading}
            materialType='floor'
            editMode='favoriteExtended'
            isDragDisabled={true}
          />
          <FavoriteGroup
            headline={t('dashboard.collection.search.facades')}
            favorites={facadeFavorites}
            isLoading={isLoading}
            materialType='facade'
            editMode='favoriteExtended'
            isDragDisabled={true}
          />
        </DragDropContext>
      </Stack>
    </>
  );
};
