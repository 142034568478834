export const quickColors: string[][] = [
  ['prod_(daw)___col_(test-farben)___Blau'],
  [
    'prod_(daw)___col_(test-farben)___Weiss',
    'prod_(daw)___col_(test-farben)___Schwarz',
  ],
  [
    'prod_(daw)___col_(test-farben)___Pink',
    'prod_(daw)___col_(test-farben)___Rot',
    'prod_(daw)___col_(test-farben)___Orange',
    'prod_(daw)___col_(test-farben)___Gruen',
  ],
];
