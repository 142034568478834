import { Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { For } from '$/components/common/Flow/For';
import { scrollable } from '$/components/common/Scrollbar';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { ColorLayer } from '$/pages/InspirationEditorPage/components/LayerSection/components/ColorLayer';
import { LoadingColorLayer } from '$/pages/InspirationEditorPage/components/LayerSection/components/LoadingColorLayer';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { repeat } from '$/utils/arrayUtils';

export const Layers = () => {
  const { t } = useTranslation();

  const activeViewIndex = useInspirationEditorStore.useActiveViewIndex();
  const { variant } = useInspirationEditorStore(
    useShallow((s) => ({ variant: s.getActiveSceneVariant() })),
  );

  const layers = variant.filter((layer) =>
    layer.views.includes(activeViewIndex.toString()),
  );

  return (
    <Stack gap='0' w='full'>
      <Text px='4' py='2' fontSize='sm' fontWeight='bold'>
        {t('inspirationEditor.layer')}
      </Text>

      <SidebarDivider />

      <Stack gap='0' pt='2' {...scrollable}>
        <For
          each={layers}
          fallback={() =>
            repeat(5).map((id: number) => (
              <LoadingColorLayer layerId={id} key={id} px='6' py='2' />
            ))
          }
        >
          {(layer) => <ColorLayer key={layer.id} layer={layer} px='6' py='2' />}
        </For>
      </Stack>
    </Stack>
  );
};
