import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { EditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { EditorModuleStatus } from '$/services/usecases/editor/mapper/editorStatus';
import { HoverStatus } from '$/services/usecases/editor/mapper/hoverStatus';
import { ModuleContextMenu } from '$/services/usecases/editor/mapper/moduleContextMenu';
import { EditorProject } from '$/services/usecases/projects/mapper/editorProject';

export const MAXZOOMLEVEL = 25600;
export const MINZOOMLEVEL = 1;

export type SelectMode = 'single' | 'multiple' | 'bulk';

type InternalState = {
  rawStatus: object;
  statusObject: EditorModuleStatus;
  editorModule: HTMLIFrameElement | null;
  isEditorModuleLoading: boolean;
  isProjectLoading: boolean;
  hoveredObject: HoverStatus;
  openedAccordionIndices: number[];
  moduleContextMenu: ModuleContextMenu | null;
  editorProject: EditorProject | null;
  isFullScreen: boolean;
  freeUserToken: string | null;
  projectName: string | null;
  isSaving: boolean;
  initialMaterialId: string | null;
};

type Actions = {
  syncState: (status: EditorModuleStatus, rawStatus: object) => void;
  setIsEditorModuleLoading: (isLoading: boolean) => void;
  setIsProjectLoading: (isLoading: boolean) => void;
  setComponentActive: (actions: EditorActions, componentIndex: number) => void;
  setLayerActive: (
    actions: EditorActions,
    layerIndex: number,
    parentIndex: number,
  ) => void;
  setZoomLevel: (newZoomLevel: number) => void;
  resetSelection: () => void;
  resetStore: () => void;
  setMaterial: (componentIndex: number, materialId: string) => void;
  setHoveredObject: (
    elementIndex: HoverStatus['elementIndex'],
    layerIndex: HoverStatus['layerIndex'],
  ) => void;
  setOpenAccordionOpenIndices: (indices: number[]) => void;
  moveAccordionItem: (fromIndex: number, toIndex: number) => void;
  shiftAccordionItems: () => void;
  deleteAccordionItem: (index: number) => void;
  setModuleContextMenu: (contextMenu: ModuleContextMenu) => void;
  setIsFullscreen: (isFullScreen: boolean) => void;
  setFreeUserToken: (token: string) => void;
  setEditorModule: (module: HTMLIFrameElement | null) => void;
};

const emptyStatus: EditorModuleStatus = {
  components: [],
  activeComponentIndex: null,
  activeLayerIndex: null,
  brushSize: 0,
  brushType: 'round',
  limitToMask: false,
  rubberSize: 0,
  rubberType: 'round',
  workMode: 'selectPoly',
  zoomPercent: 100,
  zoomType: 'zoomPercent',
  colorChecker: {
    height: 210,
    status: false,
    type: 0,
    width: 210,
    x: 100,
    y: 100,
  },
  canUndo: false,
  canRedo: false,
  magicWandTolerance: 50,
  magicWandCircumference: 1,
  magicMode: 'add',
  background: { r: 0, g: 0, b: 0 },
  isVisibilityEnabled: true,
  retouchPaintSize: 0,
  retouchPaintType: 'round',
  retouchPatternSize: 0,
  retouchPatternType: 'round',
};

const initial: InternalState & EditorModuleStatus = {
  rawStatus: {},
  statusObject: emptyStatus,
  editorModule: null,
  isEditorModuleLoading: true,
  isProjectLoading: true,
  hoveredObject: {
    elementIndex: null,
    layerIndex: null,
  },
  openedAccordionIndices: [],
  moduleContextMenu: null,
  editorProject: null,
  isFullScreen: false,
  freeUserToken: null,
  projectName: null,
  isSaving: false,
  initialMaterialId: null,
  ...emptyStatus,
};

export const getLetterFromIndex = (number: number): string => {
  let result = '';

  while (number > 0) {
    const remainder = (number - 1) % 26;
    result = String.fromCharCode(65 + remainder) + result;
    number = Math.floor((number - 1) / 26);
  }

  return result;
};

export const store = create<InternalState & Actions & EditorModuleStatus>()(
  (set, get) => ({
    ...initial,
    syncState: (status, rawStatus) => {
      set({ ...status, rawStatus, statusObject: status });
    },
    setIsEditorModuleLoading: (isLoading) => {
      set({ isEditorModuleLoading: isLoading });
    },
    setIsProjectLoading: (isLoading) => {
      set({ isProjectLoading: isLoading });
    },
    setComponentActive: (actions, componentId) => {
      actions.selectComponent(componentId);
    },
    setLayerActive: (actions, layerIndex, parentIndex) => {
      actions.selectObjects([
        { componentIndex: parentIndex, objectIndex: layerIndex },
      ]);
    },
    setZoomLevel: (newZoomLevel) => {
      set({ zoomPercent: newZoomLevel });
    },
    resetSelection: () => {
      set({ activeComponentIndex: null, activeLayerIndex: null });
    },
    resetStore: () => set({ ...initial }),
    setMaterial: (componentIndex, materialId) => {
      const components = [...get().components];
      components[componentIndex].materialId = materialId;

      set({ components });
    },
    setHoveredObject: (elementIndex, layerIndex) => {
      set({
        hoveredObject: {
          elementIndex,
          layerIndex,
        },
      });
    },
    setOpenAccordionOpenIndices: (indices) => {
      set({ openedAccordionIndices: indices });
    },
    shiftAccordionItems: () => {
      const newArray = get().openedAccordionIndices.map((index) => index + 1);
      set({ openedAccordionIndices: newArray });
    },
    moveAccordionItem: (fromIndex, toIndex) => {
      const newArray = get().openedAccordionIndices.map((index) => {
        // move to new position
        if (index === fromIndex) {
          return toIndex;
        }

        // everything in between should be moved one position when it's between from and to
        if (fromIndex < toIndex) {
          // everything in between should be moved one position when it's between from and to

          if (index > fromIndex && index <= toIndex) {
            return index - 1;
          }
        } else if (index >= toIndex && index < fromIndex) {
          return index + 1;
        }

        return index;
      });

      set({ openedAccordionIndices: newArray });
    },
    deleteAccordionItem: (deletedIndex) => {
      const newArray = get()
        .openedAccordionIndices.filter((index) => index !== deletedIndex)
        .map((item) => (item > deletedIndex ? item - 1 : item));

      set({ openedAccordionIndices: newArray });
    },
    setModuleContextMenu: (contextMenu) => {
      set({ moduleContextMenu: contextMenu });
    },
    setIsFullscreen: (isFullScreen) => {
      set({ isFullScreen });
    },
    setFreeUserToken: (token) => {
      set({ freeUserToken: token });
    },
    setEditorModule: (module) => {
      set({ editorModule: module });
    },
  }),
);

export const useEditorStore = createSelectorHooks(store);
