import { Box, Collapse, Stack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { ColorChecker } from '$/pages/EditorPage/components/AttributesSection/components/PropertyOptions/components/ColorChecker';
import { ColorProperties } from '$/pages/EditorPage/components/AttributesSection/components/PropertyOptions/components/ColorProperties';

export const PropertyOptions = () => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack gap='0' pt='3'>
      <Box onKeyDown={({ key }) => key === 'Enter' && onToggle()} tabIndex={0}>
        <Box px='4' pb='3'>
          <FilterHeader
            header={t('editor.settings')}
            isActive={isOpen}
            onClick={onToggle}
            prefix={<Icon icon='filter' boxSize='4' />}
            isCollapsible
          />
        </Box>
      </Box>

      <Collapse in={isOpen} startingHeight={0}>
        <Stack gap='0'>
          <Stack gap='0' mt='2'>
            <ColorProperties />
            <SidebarDivider mt='4' mb='3' />
            <ColorChecker />
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
