import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bg: 'bodyBackground',
    border: '1px solid',
    borderColor: 'border',
    _checked: {
      bg: 'primaryButton.background',
    },
    w: '44px',
    p: '1px',
    h: '20px',
  },
  container: {
    w: 'auto',
    h: 'auto',
  },
  thumb: {
    _checked: {
      transform: 'translateX(24px)',
    },
    transform: 'translateX(2px)',
    mt: '1px',
    h: '18px',
    w: '18px',
  },
});

export const Switch = defineMultiStyleConfig({ baseStyle });
