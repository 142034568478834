import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ProfileModal } from '$/components/core/Profile/ProfileModal';
import { Header } from '$/components/layouts/BaseDashboardLayout/components/Header';
import { MobileHeader } from '$/components/layouts/BaseDashboardLayout/components/MobileHeader';

export const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container
        as={Stack}
        gap='4'
        maxW={{ base: 'container.2xl', lg: '100%' }}
        h='100vh'
        p={{ base: '16px', mobile: '32px', xl: '64px' }}
      >
        <Box display={{ base: 'none', mobile: 'initial' }}>
          <Header />
        </Box>
        <Box display={{ base: 'initial', mobile: 'none' }}>
          <MobileHeader />
        </Box>

        <Center layerStyle='DashboardSection' h='full'>
          <Stack
            gap='44px'
            w='90%'
            maxW='630px'
            p='10'
            bg='bodyBackground'
            border='1px solid'
            borderColor='border'
            borderRadius='12px'
          >
            <Stack>
              <Heading as='h1' fontSize='h5' textAlign='center'>
                404
              </Heading>
              <Heading as='h2' fontSize='h10' textAlign='center'>
                {t('error.pageNotFoundHeader')}
              </Heading>
            </Stack>

            <Stack align='center' gap='9' textAlign='center'>
              <Icon icon='sad_emoji' boxSize='80px' />
              <Stack gap='8'>
                <Text color='lighterText' fontSize='sm' lineHeight='20px'>
                  {t('error.pageNotFoundText')}
                </Text>
                <Link to='/' replace>
                  <Button variant='primary'>
                    {t('error.pageNotAllowedButton')}
                  </Button>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Center>
      </Container>
      <ProfileModal />
    </>
  );
};
