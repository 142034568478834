import { Button, HStack } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { queryClient } from '$/services/fetcher';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface CategoryButtonProps {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const CategoryButton: FC<CategoryButtonProps> = ({
  children,
  isActive,
  onClick,
}) => {
  return (
    <Button
      boxSize='8'
      minW='0'
      p='0'
      color='lighterText'
      opacity={isActive ? '1' : '0.3'}
      onClick={onClick}
      variant='ghost'
    >
      {children}
    </Button>
  );
};

interface CategorySelectionProps {
  materialTypes?: MaterialType[];
  initialMode: MaterialType;
}

export const CategorySelection: FC<CategorySelectionProps> = ({
  materialTypes,
  initialMode,
}) => {
  const [activeType, setActiveType] = useState<FilterType>(
    initialMode as FilterType,
  );
  const { hasPermission } = useAuthorization();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  useEffect(() => setActiveType(initialMode as FilterType), [initialMode]);

  const selectGroup = (type: FilterType) => {
    setActiveType(type);

    useCollectionStore.setState({
      filter: undefined,
      activeType: type,
      filterGroups: [],
    });

    const { filterGroups } = useCollectionStore.getState();
    void queryClient
      .ensureQueryData(filterOptionsQuery({ type, filter: filterGroups }))
      .then((filter) => useCollectionStore.setState({ filter }));
  };

  const categories: {
    type: MaterialType;
    size: string;
    icon: AvailableIcons;
    isFree: boolean;
  }[] = [
    { type: 'uni', size: '5', icon: 'color_palette', isFree: true },
    { type: 'wall', size: '6', icon: 'wallpaper', isFree: false },
    { type: 'floor', size: '5', icon: 'floor_material', isFree: false },
    { type: 'facade', size: '5', icon: 'facade_material', isFree: false },
  ];

  return (
    <HStack gap='0'>
      {categories.map(({ type, size, icon, isFree }) => {
        if (materialTypes && !materialTypes.includes(type)) return null;

        return (
          <CategoryButton
            key={type}
            onClick={() => {
              if (hasPermission('Editor_Use_Texture_Categories') || isFree) {
                selectGroup(type as FilterType);
              } else {
                onOpenAuthModal('versionComparison');
              }
            }}
            isActive={activeType === type}
          >
            <Icon icon={icon} boxSize={size} />
          </CategoryButton>
        );
      })}
    </HStack>
  );
};
