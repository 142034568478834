import { useBreakpointValue } from '@chakra-ui/react';

export const useIsMobile = ({ sm }: { sm: boolean } = { sm: false }) => {
  const isMobile = !!useBreakpointValue(
    { base: true, mobile: sm, sm: false },
    { ssr: false },
  );

  return isMobile;
};
