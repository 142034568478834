import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { authLoader } from '$/components/core/Authentication/utils/authGuard';
import { ConfirmationForm } from '$/components/core/Profile/ConfirmationForm';
import { StyledLink } from '$/components/core/StyledLink';

export const ProfileModalDeleteAccount: FC = () => {
  const { t } = useTranslation();

  const email = authLoader().email;

  return (
    <>
      <Icon icon='sad_emoji' w='80px' h='80px' />
      <Text fontSize='sm'>
        <Trans
          i18nKey='dashboard.authentication.deleteAccount.instruction'
          values={{
            email: email,
            confirmation: t(
              'dashboard.authentication.deleteAccount.confirmation',
            ),
          }}
          components={{
            PrivacyLink: (
              <StyledLink
                color='primaryButton.background'
                textDecoration='underline'
                link={{
                  to: '/privacy',
                }}
              >
                Link
              </StyledLink>
            ),
          }}
        />
      </Text>

      <ConfirmationForm />
    </>
  );
};
