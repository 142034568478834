import { Box } from '@chakra-ui/react';
import { FC } from 'react';

import { outlined } from '$/components/common/Accessibility';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  color?: string;
  materialId?: string;
}

export const VisualizationBox: FC<Props> = ({ color, materialId }) => {
  const { setMaterial } = useEditorActions();
  const activeComponent = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();

  const activeMaterialId =
    activeComponent != null ? components[activeComponent].materialId : null;

  if (!color || !materialId) {
    return <Box layerStyle='VisualizationBox' />;
  }

  const onSetMaterial = () => {
    setMaterial(materialId);
  };

  return (
    <Box
      as='button'
      layerStyle='VisualizationBox'
      bg={color}
      onClick={onSetMaterial}
      {...outlined({
        visible: materialId === activeMaterialId,
      })}
    />
  );
};
