import { useEffect } from 'react';

import { FilterType } from '$/services/mapper/uses';
import {
  CollectionViewOptions,
  useCollectionStore,
} from '$/stores/useCollectionStore';

export const useCollectionViewOptions = (filterType: FilterType) => {
  const activeViewMode = useCollectionStore.useSelectedViewMode();
  const setViewMode = useCollectionStore.useSetSelectedViewMode();
  const filter = useCollectionStore.useFilterGroups();

  const hasCollectionFilter =
    filterType === 'uni' &&
    filter.filter((option) => option.type === 'collection').length > 0;

  useEffect(() => {
    if (!hasCollectionFilter && activeViewMode === 'fan') {
      setViewMode('grid');
    }
  }, [activeViewMode, hasCollectionFilter, setViewMode]);

  type Option = {
    name: CollectionViewOptions;
    isVisible: boolean;
  };

  const options: Option[] = [
    {
      name: 'grid',
      isVisible: true,
    },
    {
      name: 'fan',
      isVisible: hasCollectionFilter,
    },
    {
      name: 'list',
      isVisible: true,
    },
  ];

  if (filterType === 'all') {
    return [];
  }

  return options.filter((option) => option.isVisible);
};
