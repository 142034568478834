import { Icon } from '$/components/common/Icon';

export const getEditorObjectIcon = (type: string) => {
  switch (type) {
    case 'bitmap':
      return <Icon icon='brush' fill='editor.icon' boxSize='14px' />;
    case 'ellipse':
      return <Icon icon='shape_circle' fill='editor.icon' boxSize='14px' />;
    case 'rectangle':
      return <Icon icon='shape_rectangle' fill='editor.icon' boxSize='14px' />;
    case 'poly':
      return <Icon icon='area' fill='editor.icon' boxSize='14px' />;
    default:
      return <Icon icon='vector' fill='editor.icon' boxSize='14px' />;
  }
};
