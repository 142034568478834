import {
  Box,
  HStack,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { Draggable } from '@hello-pangea/dnd';
import { FC, MouseEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoSelectInputField } from '$/components/common/AutoSelectInputField';
import { Icon } from '$/components/common/Icon';
import { ContextMenuButton } from '$/pages/EditorPage/components/ComponentSection/ContextMenuButton';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { getEditorObjectIcon } from '$/pages/EditorPage/utils/getObjectItem';
import { ComponentLayer } from '$/services/usecases/editor/mapper/editorStatus';

interface Props {
  componentObject: ComponentLayer;
  parentIndex: number;
  index: number;
  dragIndex: number;
}

export const ComponentObjectListItem: FC<Props> = ({
  componentObject,
  parentIndex,
  index,
  dragIndex,
}) => {
  const { t } = useTranslation();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const hoveredObject = useEditorStore.useHoveredObject();
  const workMode = useEditorStore.useWorkMode();

  const isHovered =
    hoveredObject.elementIndex === parentIndex &&
    hoveredObject.layerIndex === index;

  const [name, setName] = useState<string>(
    componentObject.name != '' ? componentObject.name : componentObject.type,
  );
  const { isOpen, onClose, onOpen } = useDisclosure();

  const activeLayer = useEditorStore.useActiveLayerIndex();
  const setLayerActive = useEditorStore.useSetLayerActive();
  const activeComponent = useEditorStore.useActiveComponentIndex();
  const setHoveredObject = useEditorStore.useSetHoveredObject();
  const actions = useEditorActions();

  const handleLayerClick = (layer: ComponentLayer) => {
    setLayerActive(actions, layer.index, parentIndex);
  };

  const onEdit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key != 'Enter') return;
    actions.renameObject(parentIndex, index, name);
    setIsInEditMode(false);
  };

  const handleInputBlur = () => {
    actions.renameObject(parentIndex, index, name);
    setIsInEditMode(false);
  };

  const handleRightClick = (event: MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    onOpen();
  };

  const enableEditMode = () => {
    setIsInEditMode(true);
    onClose();
  };

  const onDelete = () => {
    actions.deleteObject(parentIndex, index);
    onClose();
  };

  const componentActive =
    parentIndex === activeComponent && activeLayer === null;

  const isActive =
    (componentActive && workMode !== 'grid') ||
    (parentIndex === activeComponent && activeLayer === componentObject.index);

  const bgColor = () => {
    if (isActive) return 'secondaryBackground';
    if (isHovered) return 'bodyBackground';
    return 'transparent';
  };

  return (
    <Draggable
      draggableId={`${parentIndex}-${index.toString()}`}
      index={dragIndex}
    >
      {(provided) => {
        if (provided.draggableProps.style?.transform != null) {
          const t = provided.draggableProps.style?.transform.split(',')[1];
          provided.draggableProps.style.transform = 'translate(0px,' + t;
        }
        return (
          <Popover isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              <ListItem
                ref={provided.innerRef}
                w='full'
                pl='7'
                bg={bgColor()}
                outline='none'
                onClick={(e) => {
                  handleLayerClick(componentObject);
                  e.stopPropagation();
                }}
                onContextMenu={handleRightClick}
                onDoubleClick={() => enableEditMode()}
                onMouseLeave={() => {
                  setHoveredObject(null, null);
                  actions.setHoveredLayer(null);
                }}
                onMouseOver={() => {
                  setHoveredObject(parentIndex, index);
                  actions.setHoveredLayer({
                    elementIndex: parentIndex,
                    layerIndex: index,
                  });
                }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <HStack justify='space-between'>
                  <HStack as='span' gap='1'>
                    {getEditorObjectIcon(componentObject.type)}
                    {isInEditMode ? (
                      <AutoSelectInputField
                        h='auto'
                        borderColor='primaryButton.background'
                        borderRadius='4px'
                        _hover={{
                          borderColor: 'primaryButton.background',
                        }}
                        onBlur={handleInputBlur}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={onEdit}
                        size='xs'
                        fontSize='sm'
                        value={name}
                      />
                    ) : (
                      <Box
                        as='span'
                        pl='1'
                        color='editor.objectText'
                        fontSize='sm'
                        userSelect='none'
                        wordBreak='break-word'
                      >
                        {componentObject.name != ''
                          ? componentObject.name
                          : componentObject.type}
                      </Box>
                    )}
                  </HStack>

                  <IconButton
                    visibility={isHovered ? 'visible' : 'hidden'}
                    aria-label={t('inspirationEditor.delete')}
                    disabled={!isHovered}
                    icon={<Icon icon='trash_can' boxSize='14px' />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                    variant='text'
                  />
                </HStack>
              </ListItem>
            </PopoverTrigger>
            <PopoverContent w='fit-content' border='none'>
              <PopoverArrow />
              <ContextMenuButton
                onClick={() => enableEditMode()}
                text={t('inspirationEditor.rename')}
              />
              <ContextMenuButton
                onClick={() => onDelete()}
                text={t('inspirationEditor.delete')}
              />
            </PopoverContent>
          </Popover>
        );
      }}
    </Draggable>
  );
};
