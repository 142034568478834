import {
  AbsoluteCenter,
  Box,
  BoxProps,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends BoxProps {
  materialColor?: string | null;
  image?: string;
  isActive?: boolean;
  isLoading?: boolean;
}

export const ColorBox: FC<Props> = ({
  isActive = false,
  isLoading = false,
  materialColor,
  image,
  ...props
}) => {
  const cursor = props.onClick != null ? 'pointer' : 'default';
  const bg = !image ? (materialColor ?? 'transparent') : 'transparent';

  return (
    <Stack pos='relative'>
      <Box
        w='24px'
        h='24px'
        bg={bg}
        bgImage={image}
        bgSize='cover'
        border={isActive ? '2px solid' : '1px solid'}
        borderColor={isActive ? 'primaryButton.background' : 'border'}
        borderRadius='2px'
        cursor={cursor}
        {...props}
      />
      {isLoading && (
        <AbsoluteCenter>
          <Spinner w='12px' h='12px' emptyColor='neutral.1000/30' />
        </AbsoluteCenter>
      )}
    </Stack>
  );
};
