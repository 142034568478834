import { useBreakpointValue } from '@chakra-ui/react';

import { EditorZoomAction } from '$/components/core/Editor/ActionBar/components/EditorZoomAction';
import { getNextZoomValue } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const EditorZoomSection = () => {
  const zoomLevel = useEditorStore.useZoomPercent();
  const isSmall = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    { ssr: false },
  );

  const { zoomPercentage, selectAction } = useEditorActions();

  const onZoomIn = () => {
    const newZoomLevel = getNextZoomValue(zoomLevel / 100, true);
    zoomPercentage(newZoomLevel * 100);
  };

  const onZoomOut = () => {
    const newZoomLevel = getNextZoomValue(zoomLevel / 100, false);
    zoomPercentage(newZoomLevel * 100);
  };

  const onResetZoom = () => {
    selectAction(ModuleSendSelectAction.ZoomInnerfit);
  };

  const setZoomLevel = (newZoomLevel: number) => {
    zoomPercentage(newZoomLevel * 100);
  };

  return (
    <EditorZoomAction
      zoomLevel={zoomLevel / 100}
      onResetZoom={onResetZoom}
      onZoomIn={onZoomIn}
      onZoomOut={onZoomOut}
      setZoomLevel={setZoomLevel}
      isSmallMode={isSmall}
    />
  );
};
