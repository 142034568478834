import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type LanguageOptions = 'de' | 'en';

export type State = {
  selectedLanguage: LanguageOptions;
  hasClosedTurnScreenModal: boolean;
};

type Actions = {
  setSelectedLanguage: (selectedLanguage: LanguageOptions) => void;
};

export const initial: State = {
  selectedLanguage:
    (localStorage.getItem('userLanguage') as LanguageOptions) ?? 'de',
  hasClosedTurnScreenModal: false,
};

export const store = create<State & Actions>()(
  persist(
    (set) => ({
      ...initial,
      setSelectedLanguage: (selectedLanguage) => set({ selectedLanguage }),
    }),
    {
      name: 'general-store',
      partialize: (state) => ({
        hasClosedTurnScreenModal: state.hasClosedTurnScreenModal,
      }),
    },
  ),
);

export const useGeneralStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
