import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FC } from 'react';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { ActionButton } from '$/components/core/Editor/ActionBar/components/ActionButton';
import { HorizontalGroup } from '$/components/core/Editor/ActionBar/components/HorizontalGroup';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  group: EditorActionGroup;
}

export const ActionGroup: FC<Props> = ({ group }) => {
  const workMode = useEditorStore.useWorkMode();
  const activeAction = group.actions.find((action) => action.mode === workMode);

  if (group.actions.length <= 1) {
    return <ActionButton action={group.actions[0]} />;
  }

  if (!group.groupable) {
    return <HorizontalGroup group={group} />;
  }

  const activeActionInsideGroup = activeAction != null;

  const groupIcon = activeAction?.icon ?? group.actions[0].icon;

  return (
    <Menu>
      <MenuButton
        as={GhostButton}
        h='30px'
        px='0'
        borderRadius='2px'
        rightIcon={<Icon icon='chevron_down' w='12px' />}
        variant={activeActionInsideGroup ? 'action' : 'ghost'}
      >
        <Button
          as='span'
          layerStyle='ActionIconButton'
          w='1.875rem'
          minW='0'
          variant={activeActionInsideGroup ? 'primary' : 'text'}
        >
          {groupIcon}
        </Button>
      </MenuButton>

      <MenuList minW='0'>
        {group.actions.map((action) => (
          <MenuItem key={action.key} as='span'>
            <ActionButton action={action} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
