import { HStack, IconButton } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { useCollectionViewOptions } from '$/hooks/useCollectionViewOptions';
import { FilterType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filterType: FilterType;
}

export const CollectionViewOptionSelector: FC<Props> = ({ filterType }) => {
  const activeViewMode = useCollectionStore.useSelectedViewMode();
  const options = useCollectionViewOptions(filterType);
  const setViewMode = useCollectionStore.useSetSelectedViewMode();

  return (
    <HStack gap='3' display={{ base: 'none', mobile: 'flex' }}>
      {options.map((option) => (
        <IconButton
          key={option.name}
          minW='0'
          minH='0'
          p='0'
          aria-label={option.name}
          icon={
            <Icon
              icon={option.name}
              height='24px'
              fill={activeViewMode === option.name ? 'text' : 'lighterText'}
              opacity={activeViewMode === option.name ? 1 : 0.3}
            />
          }
          onClick={() => setViewMode(option.name)}
          variant='text'
        />
      ))}
    </HStack>
  );
};
