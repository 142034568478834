import { Box, Stack, StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareSection';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderList';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { SceneDetailSection } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/SceneDetailSection';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const SceneDetails: FC<StackProps> = (props) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const projectId = useInspirationEditorStore.useCurrentProjectId();
  const isLoading = useInspirationEditorStore.useIsLoading();

  const layers = useInspirationEditorStore((state) =>
    state.getActiveSceneVariant(),
  ).filter(
    (layer, pos, self) =>
      self.findIndex((l) => l.material?.id === layer.material?.id) === pos,
  );

  const colorItems = layers.filter(
    (layer) => layer.material?.type.includes('uni') && !layer.material?.url(),
  );

  const wallItems = layers.filter(
    (layer) =>
      layer.material?.type.includes('wall') &&
      layer.category !== 'floor' &&
      !!layer.material?.url(),
  );

  const floorItems = layers.filter(
    (layer) =>
      layer.material?.type.includes('floor') &&
      layer.category === 'floor' &&
      !!layer.material?.url(),
  );

  const facadeItems = layers.filter(
    (layer) =>
      layer.material?.type.includes('facade') && !!layer.material?.url(),
  );

  return (
    <Stack gap='0' {...props}>
      <ShareSection px='4' py='2' type='inspiration' projectId={projectId} />

      <SidebarDivider />

      <SceneDetailSection
        icon={<Icon icon='inspiration_marker' boxSize='4' />}
        label={t('inspirationEditor.attributes.usedColors')}
        isLoading={isLoading}
        items={colorItems.map((layer) => layer.material)}
      />

      {(colorItems.length > 0 || isLoading) && <SidebarDivider />}

      <SceneDetailSection
        icon={<Icon icon='wallpaper' boxSize='4' />}
        label={t('inspirationEditor.attributes.usedWalls')}
        isLoading={isLoading}
        items={wallItems.map((layer) => layer.material)}
      />

      {(wallItems.length > 0 || isLoading) && <SidebarDivider />}

      <SceneDetailSection
        icon={<Icon icon='floor_material' w='14px' />}
        label={t('inspirationEditor.attributes.usedFloors')}
        isLoading={isLoading}
        items={floorItems.map((layer) => layer.material)}
      />

      {(floorItems.length > 0 || isLoading) && <SidebarDivider />}

      <SceneDetailSection
        icon={<Icon icon='facade_material' w='14px' />}
        label={t('inspirationEditor.attributes.usedFacades')}
        isLoading={isLoading}
        items={facadeItems.map((layer) => layer.material)}
      />

      {(facadeItems.length > 0 || isLoading) && <SidebarDivider />}

      {hasPermission('Favorites_Select') && (
        <>
          <Box px='4' pt='3' pb='4'>
            <FavoriteFolderList />
          </Box>
          <SidebarDivider />
        </>
      )}

      <Box px='4' pt='3' pb='8'>
        <ExportSection mode='inspiration' />
      </Box>
    </Stack>
  );
};
